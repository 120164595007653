// This component is used to handle errors from the React-Apollo Query
// component in a generic way:
//
//  <Query ...>
//    {(loading, error, data) => {
//      if (error) {
//        return <QueryError error={error}/>;
//      }
//    }
//  </Query>

import React from 'react';

const QueryError = ({ error, data }) => {
  let info;

  console.error(
    'Query Error: ',
    error || 'no error details',
    data || 'no data details',
  );

  if (error && error.graphQLErrors) {
    const messages = error.graphQLErrors.map(err => err.message);

    if (messages.length === 1) {
      info = <p>{messages[0]}</p>;
    } else {
      const items = messages.map((msg, i) => <li key={i}>{msg}</li>);
      info = <ul>{items}</ul>;
    }
  }

  return (
    <div>
      <h3>Misslyckades hämta data från servern!</h3>
      {info}
    </div>
  );
};

export default QueryError;
