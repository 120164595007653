import { ApolloError, useMutation, useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import CachedIcon from '@mui/icons-material/Cached';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  Paper,
  Snackbar,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { sv as locale } from '@norban/locale';
import moment from 'moment';
import React, { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import ConfirmationDialog from '../../components/ConfirmationDialog';
import FollowerSyncErrors from '../../components/FollowerSyncErrors';
import {
  BofUserFollowingsDeleteFollowerDocument,
  BofUserFollowingsDocument,
  BofUserFollowingsFollowHomeDocument,
  BofUserFollowingsFollowerFragment,
  BofUserFollowingsSyncFollowersDocument,
  BofUserFollowingsUpdateFollowerDocument,
  SyncFollowerError,
} from '../../generated/backend/graphql';
import useCheckedList from '../../hooks/useCheckedList';
import usePopupAlert from '../../hooks/usePopupAlert';
import { beige } from '../../theme';

import AddFollowingDialog from './components/AddFollowingDialog';

type Props = {
  userId: string;
};

const useStyles = makeStyles({
  horizontalScrollPaper: {
    overflowX: 'scroll',
  },
  countLabel: {
    alignSelf: 'flex-end',
    backgroundColor: beige,
    borderRadius: '10px',
    margin: '5px',
    padding: '5px 10px',
  },
});

const L = locale.backoffice.userFollowings;

const UserFollowings = ({ userId }: Props) => {
  const momentFormat = locale.backoffice.generic.dateAndTimeFormat;
  const [selectedFollowing, setSelectedFollowing] = useState<{
    adress: string;
    id: string;
  }>();
  const [confirmDeleteVisible, setConfirmDeleteVisible] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string | null>(null);
  const [syncErrors, setSyncErrors] = useState<SyncFollowerError[]>();
  const [addFollowingVisible, setAddFollowingVisible] = useState(false);
  const [showInactive, setShowInactive] = useState(false);
  const { PopupAlert, showPopupAlert } = usePopupAlert();
  const styles = useStyles();

  const {
    data,
    loading,
    error,
    refetch: refetchFollowings,
  } = useQuery(BofUserFollowingsDocument, {
    variables: {
      userId,
    },
  });

  const [deleteFollower] = useMutation(BofUserFollowingsDeleteFollowerDocument);
  const [updateFollower] = useMutation(BofUserFollowingsUpdateFollowerDocument);
  const [followHome] = useMutation(BofUserFollowingsFollowHomeDocument, {
    refetchQueries: [BofUserFollowingsDocument],
  });
  const [syncFollowers, { loading: syncFollowersLoading }] = useMutation(
    BofUserFollowingsSyncFollowersDocument,
  );

  const filteredFollowers = useMemo(() => {
    const filtered = (data?.followers ?? [])
      .filter(({ active }) => showInactive || active)
      .map(follower => {
        const followerTypes = follower.isViewingParticipant
          ? [locale.backoffice.allFollowers.viewingParticipant]
          : [];

        if (
          data?.homeMessages.find(
            homeMessage => homeMessage.toHomeId === follower.homeId,
          )
        ) {
          followerTypes.push(locale.backoffice.allFollowers.sentGreeting);
        }

        return {
          ...follower,
          followerTypes,
        };
      });
    return filtered;
  }, [data, showInactive]);

  const { checkedList, toggle, toggleAll, clearAll, isChecked, isAllChecked } =
    useCheckedList<BofUserFollowingsFollowerFragment>(filteredFollowers);

  const handleDeleteSelectedFollowing = useCallback(async () => {
    try {
      if (!selectedFollowing?.id) {
        throw new Error('Undefined follower id');
      }
      await deleteFollower({
        variables: { id: selectedFollowing.id },
      });
    } catch {
      setSnackbarMessage(L.failedRemovingFollowing);
    } finally {
      refetchFollowings();
      setConfirmDeleteVisible(false);
    }
  }, [deleteFollower, refetchFollowings, selectedFollowing?.id]);

  const handleUpdateFollowing = useCallback(
    async (active: boolean, id: string) => {
      try {
        await updateFollower({
          variables: {
            id,
            input: { active: !active },
          },
        });
      } catch {
        setSnackbarMessage(L.failedUpdatingFollowing);
      } finally {
        refetchFollowings();
      }
    },
    [refetchFollowings, updateFollower],
  );

  const handleAddFollowing = useCallback(() => {
    setAddFollowingVisible(true);
  }, []);

  const handleSync = useCallback(async () => {
    try {
      setSyncErrors(undefined);
      const res = await syncFollowers({
        variables: { followerIds: checkedList.map(({ id }) => id) },
      });

      if (
        res.data?.syncFollowers.errors &&
        res.data?.syncFollowers.errors.length > 0
      ) {
        showPopupAlert(locale.backoffice.errors.sync, 'error');
        setSyncErrors(res.data?.syncFollowers.errors);
      } else {
        showPopupAlert(locale.backoffice.allFollowers.syncSuccess, 'success');
      }
    } catch (error) {
      const ae = error as ApolloError;
      showPopupAlert(ae.message, 'error');
    }
  }, [checkedList, showPopupAlert, syncFollowers]);

  if (error) {
    return <Alert severity="error">{locale.backoffice.errors.general}</Alert>;
  }

  return (
    <>
      <Snackbar
        open={!!snackbarMessage}
        onClose={() => setSnackbarMessage(null)}
        message={snackbarMessage}
        autoHideDuration={5000}
      />
      <ConfirmationDialog
        content={
          <>
            <div>{`${L.id}: ${selectedFollowing?.id}`}</div>
            <div>{`${L.address}: ${selectedFollowing?.adress}`}</div>
          </>
        }
        title={L.removeFollowing}
        open={confirmDeleteVisible}
        onCancel={() => setConfirmDeleteVisible(false)}
        onConfirm={handleDeleteSelectedFollowing}
      />
      <AddFollowingDialog
        open={addFollowingVisible}
        onConfirm={async homeId => {
          await followHome({
            variables: {
              homeId,
              userId,
            },
          });
          setAddFollowingVisible(false);
        }}
        onCancel={async () => setAddFollowingVisible(false)}
      />
      <Card>
        <CardContent>
          <Stack
            spacing={2}
            alignItems="flex-start"
            direction="row"
            useFlexGap
            flexWrap="wrap"
          >
            <Button
              color="secondary"
              size="small"
              startIcon={<AddIcon />}
              variant="contained"
              onClick={handleAddFollowing}
            >
              {L.addFollowing}
            </Button>
            <Button
              color="secondary"
              disabled={checkedList.length === 0 || syncFollowersLoading}
              size="small"
              startIcon={<CachedIcon />}
              variant="contained"
              onClick={handleSync}
            >
              Synka
            </Button>
            <FormControlLabel
              label="Visa inaktiva"
              control={
                <Switch
                  value={showInactive}
                  checked={showInactive}
                  color="primary"
                  onChange={() => setShowInactive(!showInactive)}
                />
              }
            />
          </Stack>
        </CardContent>
        {loading ? (
          <Box sx={{ justifyContent: 'center', display: 'flex' }}>
            <CircularProgress />
          </Box>
        ) : (
          <Paper className={styles.horizontalScrollPaper}>
            <Stack>
              <Typography className={styles.countLabel} variant="subtitle2">
                {locale.backoffice.allFollowers.count}:{' '}
                {filteredFollowers.length}
              </Typography>
              <Table size="small" aria-label="home follower table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Checkbox
                        checked={isAllChecked()}
                        disabled={filteredFollowers.length === 0}
                        onChange={e => {
                          if (e.target.checked) {
                            toggleAll();
                          } else {
                            clearAll();
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell>{L.address}</TableCell>
                    <TableCell>{L.city}</TableCell>
                    <TableCell>{L.status}</TableCell>
                    <TableCell>{L.type}</TableCell>
                    <TableCell>{L.realtor}</TableCell>
                    <TableCell>{L.homeId}</TableCell>
                    <TableCell>{L.created}</TableCell>
                    <TableCell>{L.changed}</TableCell>
                    <TableCell>{L.toggleActive}</TableCell>
                    <TableCell>{L.delete}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredFollowers.map(follower => {
                    const {
                      active,
                      createdAt,
                      followerTypes,
                      home,
                      id,
                      updatedAt,
                    } = follower;

                    return (
                      <TableRow key={id} sx={{ opacity: active ? 1 : 0.5 }}>
                        <TableCell
                          onClick={e => {
                            e.stopPropagation();
                            toggle(follower);
                          }}
                        >
                          <Checkbox checked={isChecked(follower)} />
                        </TableCell>
                        <TableCell>
                          {home?.address.streetAddress ?? '-'}
                        </TableCell>
                        <TableCell>{home?.address.city ?? '-'}</TableCell>
                        <TableCell>
                          {home?.state
                            ? locale.backoffice.enumerations.HomeStateShort[
                                home.state
                              ]
                            : '-'}
                        </TableCell>
                        <TableCell>
                          {followerTypes.length > 0
                            ? followerTypes.join(', ')
                            : '-'}
                        </TableCell>
                        <TableCell>{home?.realtor?.name ?? '-'}</TableCell>
                        <TableCell>
                          <Link
                            rel="noopener noreferrer"
                            target="_blank"
                            to={`/homes/${home?.id}`}
                          >
                            {home?.id ?? '-'}
                          </Link>
                        </TableCell>
                        <TableCell>
                          {moment(createdAt).format(momentFormat)}
                        </TableCell>
                        <TableCell>
                          {moment(updatedAt).format(momentFormat)}
                        </TableCell>
                        <TableCell>
                          <IconButton
                            aria-label="activate/deactivate"
                            onClick={async evt => {
                              evt.stopPropagation();
                              handleUpdateFollowing(active, id);
                            }}
                            size="large"
                          >
                            {active ? <DoneIcon /> : <RemoveIcon />}
                          </IconButton>
                        </TableCell>
                        <TableCell>
                          <IconButton
                            aria-label="delete"
                            onClick={evt => {
                              evt.stopPropagation();
                              setSelectedFollowing({
                                adress: home?.address.streetAddress ?? '-',
                                id,
                              });
                              setConfirmDeleteVisible(true);
                            }}
                            size="large"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Stack>
          </Paper>
        )}
        {syncErrors && <FollowerSyncErrors errors={syncErrors} />}
      </Card>
      <PopupAlert />
    </>
  );
};

export default UserFollowings;
