import { useQuery } from '@apollo/client';
import {
  CardContent,
  Divider,
  Input,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { sv as locale } from '@norban/locale';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import PageHeader from '../../components/PageHeader';
import QueryError from '../../components/QueryError';
import QueryLoading from '../../components/QueryLoading';
import RootCard from '../../components/RootCard';
import SpinnerRow from '../../components/SpinnerRow';
import {
  BofAreasDocument,
  BofFollowerCountDocument,
  BofFollowersDocument,
  FollowerOrder,
  FollowerUniqueType,
} from '../../generated/backend/graphql';
import { defaultState as defaultFollowerFilterState } from '../../reducers/followerFilter';
import { findAreaByIds, flattenAreas } from '../../utils/area';

import FollowersTable, {
  FollowerFilterType,
} from './components/FollowersTable';
import SearchForm, { MAX_LIMIT_FOLLOWERS } from './SearchForm';

const useStyles = makeStyles(() => ({
  paper: {
    width: '100%',
    overflowX: 'auto',
  },
}));

const Followers = () => {
  const classes = useStyles();

  const L = locale.backoffice.followers;

  const filter = useSelector<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    any,
    FollowerFilterType
  >(state => ({
    ...defaultFollowerFilterState,
    ...state.followerFilter,
  }));

  const {
    loading: areasLoading,
    error: areasError,
    data: areasData,
  } = useQuery(BofAreasDocument);

  const { rootAreas } = areasData ?? { rootAreas: [] };

  const areaIds = useMemo(
    () =>
      findAreaByIds(filter.areaIds, rootAreas ?? [])
        .flatMap(area => flattenAreas(area))
        .map(({ id }) => id),
    [filter.areaIds, rootAreas],
  );

  const groomLimit = (limit: string) => {
    const value = limit ? parseInt(limit, 10) : MAX_LIMIT_FOLLOWERS;

    if (value < 0) {
      return 0;
    }
    if (value > MAX_LIMIT_FOLLOWERS) {
      return MAX_LIMIT_FOLLOWERS;
    }
    return value;
  };

  // Convert stored config under a transition time
  let convertedUnique = FollowerUniqueType.None;
  if (typeof filter.unique === 'boolean') {
    if (filter.unique === true) {
      convertedUnique = FollowerUniqueType.Latest;
    }
  }

  const buildFilter = (withLimit: boolean) => ({
    areaIds: areaIds || [],
    minRooms: filter.roomsRange[0],
    maxRooms: filter.roomsRange[1],
    minLivingArea: filter.livingAreaRange[0],
    maxLivingArea: filter.livingAreaRange[1],
    unique: convertedUnique,
    active: filter.active,
    homeStates: filter.homeStates,
    latestCount: withLimit ? groomLimit(filter.limit) : undefined,
    homeId: filter.homeId,
  });
  const {
    loading: followersLoading,
    error: followersError,
    data: followersData,
  } = useQuery(BofFollowersDocument, {
    variables: {
      followerOptions: {
        filter: buildFilter(true),
        orderBy: FollowerOrder.UpdatedAt,
      },
    },
    skip: areasLoading || !areasData,
  });

  const { data: followerCountData } = useQuery(BofFollowerCountDocument, {
    variables: {
      followerOptions: {
        filter: buildFilter(false),
      },
    },
    skip: areasLoading || !areasData,
  });

  const [freeSearchTerm, setFreeSearchTerm] = useState('');

  const { followers } = followersData || {};

  type FollowersExceptUndefined = Exclude<typeof followers, undefined>;

  type SearchProperties =
    | keyof FollowersExceptUndefined[0]
    | 'name'
    | 'email'
    | 'phone';
  const searchProperties: SearchProperties[] = [
    'createdAt',
    'name',
    'email',
    'phone',
    'id',
    'homeId',
  ];

  // Works since the field in the table we want to search are the same property on both user & home objects
  const includesSearchTerm = (toSearch: string) =>
    toSearch && toSearch.toLowerCase().includes(freeSearchTerm.toLowerCase());

  const followersFiltered = followers
    ?.map(follower => ({
      ...follower,
      name: follower.user?.name,
      email: follower.user?.email,
      phone: follower.user?.phone,
    }))
    .filter(follower => {
      if (!freeSearchTerm?.length) {
        return true;
      }
      return searchProperties.some((searchProperty: keyof typeof follower) => {
        const value = follower[searchProperty];
        if (typeof value !== 'string') {
          return false;
        }

        return includesSearchTerm(value);
      });
    });

  if (areasLoading) {
    return <QueryLoading />;
  }

  if (areasError) {
    return <QueryError error={areasError} data={areasError} />;
  }

  return (
    <>
      <PageHeader
        title={L.followers}
        buttons={undefined}
        links={undefined}
        overline={undefined}
      />

      <RootCard>
        <CardContent>
          <SearchForm filter={filter} rootAreas={rootAreas} />

          <Divider />

          {followersLoading && <SpinnerRow />}

          {!followersLoading && (
            <Stack mt={2} spacing={2}>
              <Typography>
                {followerCountData?.followerCount !== undefined
                  ? followerCountData?.followerCount
                  : '-'}{' '}
                {followerCountData?.followerCount === 1
                  ? L.follower.toLowerCase()
                  : L.followers.toLowerCase()}
              </Typography>

              <Input
                type="search"
                placeholder="Sök bland resultat"
                value={freeSearchTerm || ''}
                onChange={e => setFreeSearchTerm(e.target.value)}
              />
            </Stack>
          )}
        </CardContent>
        {!followersLoading && (
          <Paper className={classes.paper}>
            <FollowersTable
              filter={filter}
              followers={followersFiltered ?? []}
              followersError={!!followersError}
              followersLoading={followersLoading}
            />
          </Paper>
        )}
      </RootCard>
    </>
  );
};

export default Followers;
