import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Slider,
  TextField,
  Typography,
} from '@mui/material';
import { sv as locale } from '@norban/locale';
import React from 'react';
import { useDispatch } from 'react-redux';

import { updateFilter } from '../../actions/followerActions';
import MultiAreaSelect from '../../components/MultiAreaSelect';
import { HomeState } from '../../generated/backend/graphql';

export const MAX_LIMIT_FOLLOWERS = 1000;

const SearchForm = ({ filter, rootAreas }) => {
  const L = locale.backoffice;

  const globalDispatch = useDispatch();

  const activeStringToFilter = activeString => {
    switch (activeString) {
      case 'ACTIVE':
        return true;
      case 'INACTIVE':
        return false;
      default:
        return undefined;
    }
  };

  const activeFilterToString = activeFilter => {
    switch (activeFilter) {
      case true:
        return 'ACTIVE';
      case false:
        return 'INACTIVE';
      default:
        return 'ALL';
    }
  };

  return (
    <Grid container columnSpacing={4} mb={2} rowSpacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={5}>
        <Box maxWidth={400}>
          <MultiAreaSelect
            label="Områden"
            areas={rootAreas ?? []}
            value={filter.areaIds ?? []}
            onValueChanged={areas => {
              updateFilter(globalDispatch, { areaIds: areas });
            }}
          />
        </Box>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={4}>
        <Box>
          <Typography id="rooms-range-slider" gutterBottom>
            {L.searchFollowers.rooms}
          </Typography>
          <Box px={1}>
            <Slider
              value={filter.roomsRange}
              getAriaValueText={value => value}
              valueLabelDisplay="auto"
              aria-labelledby="rooms-range-slider"
              onChange={(e, value) => {
                updateFilter(globalDispatch, { roomsRange: value });
              }}
              step={1}
              marks
              min={1}
              max={15}
            />
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={4}>
        <Box>
          <Typography id="rooms-range-slider" gutterBottom>
            {L.searchFollowers.livingArea}
          </Typography>
          <Box px={1}>
            <Slider
              value={filter.livingAreaRange}
              getAriaValueText={value => value}
              valueLabelDisplay="auto"
              aria-labelledby="rooms-range-slider"
              onChange={(e, value) => {
                updateFilter(globalDispatch, { livingAreaRange: value });
              }}
              step={5}
              marks
              min={0}
              max={400}
            />
          </Box>
        </Box>
      </Grid>

      <Grid item>
        <Box>
          <Typography id="unique-checkbox" gutterBottom>
            {L.searchFollowers.unique}
          </Typography>
          <Checkbox
            checked={filter.unique}
            onChange={e => {
              updateFilter(globalDispatch, { unique: e.target.checked });
            }}
          />
        </Box>
      </Grid>
      <Grid item>
        <Box>
          <Typography gutterBottom>{L.searchFollowers.status}</Typography>
          <RadioGroup
            name="radio-buttons-group"
            row
            value={activeFilterToString(filter.active)}
            onChange={e => {
              updateFilter(globalDispatch, {
                active: activeStringToFilter(e.target.value),
              });
            }}
          >
            <FormControlLabel
              control={<Radio />}
              label={L.searchFollowers.active}
              value="ACTIVE"
            />
            <FormControlLabel
              control={<Radio />}
              label={L.searchFollowers.inactive}
              value="INACTIVE"
            />
            <FormControlLabel
              control={<Radio />}
              label={L.searchFollowers.all}
              value="ALL"
            />
          </RadioGroup>
        </Box>
      </Grid>

      <Grid item>
        <Box>
          <Typography id="limit-textfield" gutterBottom>
            {L.searchFollowers.limit} (&lt; {MAX_LIMIT_FOLLOWERS})
          </Typography>
          <TextField
            value={filter.limit}
            name="limit"
            onChange={e => {
              updateFilter(globalDispatch, { limit: e.target.value });
            }}
            type="number"
          />
        </Box>
      </Grid>

      <Grid item>
        <Box>
          <Typography id="limit-textfield" gutterBottom>
            {L.searchFollowers.homeId}
          </Typography>
          <TextField
            value={filter.homeId}
            name="limit"
            onChange={e => {
              updateFilter(globalDispatch, { homeId: e.target.value });
            }}
            type="number"
          />
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box>
          {Object.values(HomeState).map(state => {
            const checked = filter.homeStates.includes(state);

            return (
              <FormControlLabel
                key={state}
                label={
                  <Typography style={{ fontSize: 15 }}>
                    {L.enumerations.HomeState[state]}
                  </Typography>
                }
                control={
                  <Checkbox
                    checked={checked}
                    color="primary"
                    onChange={() => {
                      const newSearchHomeState = checked
                        ? filter.homeStates.filter(
                            currentState => currentState !== state,
                          )
                        : [...filter.homeStates, state];
                      updateFilter(globalDispatch, {
                        homeStates: newSearchHomeState,
                      });
                    }}
                  />
                }
              />
            );
          })}
        </Box>
      </Grid>
    </Grid>
  );
};

export default SearchForm;
