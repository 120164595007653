import { useMutation, gql } from '@apollo/client';
import { Button, Snackbar } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { sv as locale } from '@norban/locale';
import React, { useState } from 'react';

import ConfirmationDialog from './ConfirmationDialog';

const M_BUMP_HOME = gql`
  mutation BofBumpHome($homeId: ID!) {
    bumpHome(homeId: $homeId) {
      sortingTimestamp
    }
  }
`;

const useStyles = makeStyles(() => ({
  button: {
    whiteSpace: 'nowrap',
  },
}));

const BumpHomeButton = ({ homeId }) => {
  const L = locale.backoffice.homeProfileActionsCard.bumpHome;
  const classes = useStyles();
  const [dialogOpen, showDialog] = useState(false);
  const [snackbarOpen, showSnackbar] = useState(false);
  const [bumpHome] = useMutation(M_BUMP_HOME, {
    onCompleted: () => showSnackbar(true),
  });

  const handleBumpButtonClick = () => showDialog(true);
  const handleCancel = () => showDialog(false);
  const handleConfirm = bumpHome => {
    showDialog(false);
    bumpHome({ variables: { homeId } });
  };

  return (
    <div>
      <Button
        className={classes.button}
        color="primary"
        size="small"
        onClick={handleBumpButtonClick}
      >
        {L.title}
      </Button>

      <ConfirmationDialog
        open={dialogOpen}
        title={L.confirmation.title}
        content={L.confirmation.content}
        onConfirm={() => handleConfirm(bumpHome)}
        onCancel={handleCancel}
      />

      <Snackbar
        open={snackbarOpen}
        onClose={() => showSnackbar(false)}
        message={L.success}
        autoHideDuration={5000}
      />
    </div>
  );
};

export default BumpHomeButton;
