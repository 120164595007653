import { useQuery, useSubscription } from '@apollo/client';
import AlignVerticalBottomIcon from '@mui/icons-material/AlignVerticalBottom';
import ChatIcon from '@mui/icons-material/Chat';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import HistoryIcon from '@mui/icons-material/History';
import LabelIcon from '@mui/icons-material/Label';
import PersonIcon from '@mui/icons-material/Person';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Badge, Tab, Tabs, useMediaQuery, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Box, Stack, Theme } from '@mui/system';
import { sv as locale } from '@norban/locale';
import React, { useEffect, useMemo, useState } from 'react';
import { Link, Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import QueryError from '../../components/QueryError';
import QueryLoading from '../../components/QueryLoading';
import UserAndHomeHeader from '../../components/UserAndHomeHeader';
import UserEmailDialog from '../../components/UserEmailDialog';
import {
  BofChatMessageCreatedDocument,
  BofUserViewDocument,
  UserBaseFragment,
} from '../../generated/backend/graphql';
import { useBackendSubscriptionClient } from '../../hooks/useBackendClient';
import { isabelline } from '../../theme';

import ChatView from './components/ChatView';
import EditLabels from './components/EditLabels';
import LeadTemplates from './components/LeadTemplates';
import UserAnalytics from './components/UserAnalytics';
import UserCrmAndLog from './components/UserCrmAndLog';
import UserInterests from './components/UserInterests';
import UserOverview from './components/UserOverview';
import UserProfile from './components/UserProfile';
import UserUploadedFiles from './components/UserUploadedFiles';

const useStyles = makeStyles((theme: Theme) => ({
  tabs: {
    backgroundColor: isabelline,
    position: 'sticky',
    top: 64,
    zIndex: 2,
    [theme.breakpoints.down('sm')]: {
      top: 56,
    },
  },
  tab: {
    minWidth: 'auto',
  },
}));

type Props = {
  user: UserBaseFragment;
  tab?: string;
  basePath: string;
};
const User = ({ user, tab = undefined, basePath }: Props) => {
  const L = locale.backoffice.userPage;
  const theme = useTheme();
  const styles = useStyles();
  const smallDisplay = useMediaQuery(theme.breakpoints.down('sm'));
  const [emailDialogOpen, setEmailDialogOpen] = useState(false);

  // Set special favicon for user page
  useEffect(() => {
    const link = document.querySelector('link[rel="icon"]');
    link?.setAttribute('href', '/favicon-user.svg');
  }, []);

  const subscriptionClient = useBackendSubscriptionClient();

  const { data: chatSubscription } = useSubscription(
    BofChatMessageCreatedDocument,
    {
      client: subscriptionClient,
      variables: { user: user.id, broadcast: false },
    },
  );

  const tabs: {
    subPath: string;
    title: React.ReactNode;
    icon: React.ReactNode;
    component: React.ReactNode;
  }[] = useMemo(
    () => [
      {
        icon: <VisibilityIcon />,
        title: L.tabs.overview,
        subPath: `overview`,
        component: <UserOverview userId={user.id} />,
      },
      {
        icon: <HistoryIcon />,
        title: L.tabs.crmAndLog,
        subPath: `crm-log`,
        component: (
          <UserCrmAndLog userId={user.id} homeId={user.home?.id ?? undefined} />
        ),
      },
      {
        icon: <TravelExploreIcon />,
        title: L.tabs.interests,
        subPath: `interests`,
        component: <UserInterests userId={user.id} />,
      },
      {
        icon: <ChatIcon />,
        title:
          typeof user.unreadMessages === 'number' && user.unreadMessages > 0 ? (
            <Badge color="primary" badgeContent={user.unreadMessages}>
              {L.tabs.chat}
            </Badge>
          ) : (
            L.tabs.chat
          ),
        subPath: `chat`,
        component: (
          <ChatView
            userId={user.id}
            lastKnownMessageId={chatSubscription?.chatMessageCreated?.id}
          />
        ),
      },
      {
        icon: <CloudUploadIcon />,
        title: L.tabs.userFiles,
        subPath: `user-files`,
        component: <UserUploadedFiles id={user.id} />,
      },
      {
        icon: <PersonIcon />,
        title: L.tabs.userProfile,
        subPath: `profile`,
        component: <UserProfile userId={user.id} />,
      },
      {
        icon: <AlignVerticalBottomIcon />,
        title: 'Analytics',
        subPath: `user-analytics`,
        component: <UserAnalytics id={user.id} />,
      },
      {
        icon: <ForwardToInboxIcon />,
        title: L.tabs.process,
        subPath: `manage-leads`,
        component: (
          <LeadTemplates userId={user.id} email={user.email ?? undefined} />
        ),
      },
      {
        icon: <LabelIcon />,
        title: L.tabs.userLabels,
        subPath: `user-labels`,
        component: <EditLabels id={user.id} />,
      },
    ],
    [
      L.tabs.chat,
      L.tabs.crmAndLog,
      L.tabs.interests,
      L.tabs.overview,
      L.tabs.process,
      L.tabs.userFiles,
      L.tabs.userLabels,
      L.tabs.userProfile,
      chatSubscription?.chatMessageCreated?.id,
      user.home?.id,
      user.id,
      user.unreadMessages,
      user.email,
    ],
  );

  return (
    <>
      <Stack direction="column" spacing={2}>
        <UserAndHomeHeader
          user={user}
          homeId={user.home?.id ?? undefined}
          type="user"
          onEmailButtonClicked={() => setEmailDialogOpen(true)}
        />
        <Tabs className={styles.tabs} variant="scrollable" value={tab}>
          {tabs.map(({ subPath, icon, title }) => (
            <Tab
              className={styles.tab}
              key={subPath}
              value={subPath}
              label={smallDisplay ? icon : title}
              component={Link}
              to={`${basePath}/${subPath}`}
            />
          ))}
        </Tabs>
        <Box>
          <Switch>
            {tabs.map(({ subPath, component }) => (
              <Route key={subPath} path={`${basePath}/${subPath}`}>
                {component}
              </Route>
            ))}

            <Route>
              <Redirect to={`${basePath}/${tabs[0]?.subPath}`} />
            </Route>
          </Switch>
        </Box>
      </Stack>
      <UserEmailDialog
        open={emailDialogOpen}
        userId={user.id}
        onSent={() => {}}
        onClose={() => {
          setEmailDialogOpen(false);
        }}
      />
    </>
  );
};

const UserRouter = ({ userId }: { userId: string }) => {
  const { url } = useRouteMatch();

  const {
    loading,
    error,
    data,
    refetch: refetchUser,
  } = useQuery(BofUserViewDocument, {
    variables: { id: userId },
  });

  useEffect(() => {
    refetchUser();
  }, [refetchUser, data?.user?.home?.userId]);

  if (loading) {
    return <QueryLoading />;
  }

  if (error || !data?.user) {
    return <QueryError error={error} data={data} />;
  }

  return (
    <Switch>
      <Route path={url} exact>
        <User user={data.user} basePath={url} />
      </Route>
      <Route
        path={`${url}/:tab`}
        render={({
          match: {
            params: { tab },
          },
        }) => <User user={data.user} tab={tab} basePath={url} />}
      />
    </Switch>
  );
};

export default UserRouter;
