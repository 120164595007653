import cx from 'classnames';
import React, { useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import './DropZone.css';

export function DropZone({ onFileSelected, loading }) {
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      onDropAccepted: files => onFileSelected(files[0]),
      multiple: false,
      disabled: loading,
    });

  const className = useMemo(
    () =>
      cx('DropZone-container', {
        focus: isFocused,
        accept: isDragAccept,
        reject: isDragReject,
      }),
    [isDragAccept, isDragReject, isFocused],
  );

  return (
    <div {...getRootProps({})} className={className}>
      <input {...getInputProps()} />
      {loading ? (
        <p>Laddar upp...</p>
      ) : (
        <p>Släpp filer här för att ladda upp</p>
      )}
    </div>
  );
}
