import {
  ApolloClient,
  ApolloLink,
  fromPromise,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { useEffect, useMemo } from 'react';
import { SubscriptionClient } from 'subscriptions-transport-ws';

import { BACKEND_URI } from '../config';

import useSession from './useSession';

export function useBackendClient() {
  const { jwt, refreshJwt } = useSession();

  // replaces createHttpLink from apollo. Enables uploads.
  const httpLink = useMemo(
    () =>
      createUploadLink({
        uri: BACKEND_URI,
        headers: {
          'apollo-require-preflight': 'true',
        },
      }),
    [],
  );

  const authLink = useMemo(
    () =>
      setContext((_, { headers }) => {
        return {
          headers: {
            ...headers,
            authorization: jwt ? `Bearer ${jwt}` : '',
            // Avoid cached results from the backend
            Pragma: 'no-cache',
            'Cache-Control': 'no-cache',
          },
        };
      }),
    [jwt],
  );

  const badTokenLink = useMemo(
    () =>
      onError(({ graphQLErrors, operation, forward }) => {
        if (
          !graphQLErrors?.some(
            error => error.extensions?.code === 'UNAUTHENTICATED',
          )
        ) {
          return;
        }

        // Based on https://community.apollographql.com/t/refreshing-access-and-refresh-tokens-via-apollo-in-react/1440/5
        // eslint-disable-next-line consistent-return
        return fromPromise(refreshJwt().catch(() => {})).flatMap(newToken => {
          operation.setContext({
            headers: {
              ...operation.getContext().headers,
              authorization: newToken ? `Bearer ${newToken}` : undefined,
            },
          });

          return forward(operation);
        });
      }),
    [refreshJwt],
  );

  const link = useMemo(
    () => ApolloLink.from([authLink, badTokenLink, httpLink]),
    [authLink, badTokenLink, httpLink],
  );

  const apolloClient = useMemo(
    () =>
      new ApolloClient({
        link: ApolloLink.empty(),
        cache: new InMemoryCache(),
        name: 'backoffice',
      }),
    [],
  );

  useMemo(() => {
    apolloClient.setLink(link);
  }, [apolloClient, link]);

  return apolloClient;
}

export function useBackendSubscriptionClient() {
  const { jwt, refreshJwt } = useSession();

  // The subscription client needs to be recreated when the JWT changes
  // We also need to detect when the JWT is expired and refresh it
  const subscriptionClient = useMemo(() => {
    const client: SubscriptionClient = new SubscriptionClient(
      BACKEND_URI.replace('http', 'ws') ?? '',
      {
        lazy: false,
        reconnect: true,
        connectionCallback: error => {
          if (error) {
            // If error has message and it is "jwt expired", refresh JWT
            if ((error as { message?: string }).message === 'jwt expired') {
              refreshJwt();
            }
          }
        },

        connectionParams: { Authorization: jwt ? `Bearer ${jwt}` : null },
      },
    );

    return client;
  }, [jwt, refreshJwt]);

  // Close old subscription client when a new one is created
  useEffect(
    () => () => {
      // This is a hack to avoid the client trying to reconnect
      (subscriptionClient as unknown as { reconnect: boolean }).reconnect =
        false;
      subscriptionClient.close(true, true);
    },
    [subscriptionClient],
  );

  const subscriptionApolloClient = useMemo(() => {
    return new ApolloClient({
      link: new WebSocketLink(subscriptionClient),
      cache: new InMemoryCache(),
      name: 'backoffice-subscriptions',
    });
  }, [subscriptionClient]);

  return subscriptionApolloClient;
}
