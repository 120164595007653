import {
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { sv as locale } from '@norban/locale';
import React, { useEffect, useState } from 'react';

import {
  BiddingState,
  BofHomeProfileHomeFragment,
} from '../../../../generated/backend/graphql';
import { DispatchType } from '../../hooks/useHomeProfileReducer';

type Props = {
  home: BofHomeProfileHomeFragment;
  dispatch: DispatchType;
};

const BiddingCard = ({ home, dispatch }: Props) => {
  const [biddingState, setBiddingState] = useState(BiddingState.Closed);
  const L = locale.backoffice.homeBiddingCard;
  const handleChange = (evt: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setBiddingState(evt.target.value as BiddingState);
    dispatch({ key: 'biddingState', value: evt.target.value as BiddingState });
  };

  useEffect(() => {
    if (home.biddingState) {
      setBiddingState(home.biddingState);
    }
  }, [home]);

  return (
    <Card>
      <CardHeader title={L.title} />
      <CardContent>
        <RadioGroup
          aria-label="Budgivning"
          value={biddingState}
          onChange={handleChange}
        >
          <FormControlLabel
            value={BiddingState.Closed}
            control={<Radio />}
            label={L[BiddingState.Closed]}
          />
          <FormControlLabel
            value={BiddingState.Active}
            control={<Radio />}
            label={L[BiddingState.Active]}
          />
          <FormControlLabel
            value={BiddingState.ActiveHighestBid}
            control={<Radio />}
            label={L[BiddingState.ActiveHighestBid]}
          />
          <FormControlLabel
            value={BiddingState.ActiveHistory}
            control={<Radio />}
            label={L[BiddingState.ActiveHistory]}
          />
        </RadioGroup>
      </CardContent>
    </Card>
  );
};

export default BiddingCard;
