import React from 'react';

import MultiSelect from '../../../components/form/MultiSelect';

const MultiLabelSelect = ({
  enabled = true,
  label,
  labels,
  value,
  onValueChanged,
  placeholder = undefined,
}: {
  enabled?: boolean;
  label: string;
  labels: { id: string; label: string }[];
  placeholder?: string;
  value: boolean[];
  onValueChanged: (b: boolean[]) => void;
}) => {
  const options = labels.map(({ id, label }) => ({ key: id, value: label }));

  return (
    <MultiSelect
      enabled={enabled}
      options={options}
      label={label}
      placeholder={placeholder}
      value={labels.map(({ id }) => id).filter((_id, i) => value[i])}
      onValueChanged={newValue => {
        onValueChanged(
          labels.map(({ id }) => {
            return newValue?.includes(id);
          }),
        );
      }}
    />
  );
};

export default MultiLabelSelect;
