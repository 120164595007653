import { NoAccountsOutlined, VerifiedUserOutlined } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { DataGrid } from '@mui/x-data-grid';
import { sv as locale } from '@norban/locale';
import cx from 'classnames';
import moment from 'moment';
import React from 'react';

import TextField from '../../../components/form/TextField';
import {
  BofUserDeviceFragment,
  BofUserSessionInfoFragment,
  BofUserUnsubscribedChannelsFragment,
  BofUserUserFragment,
  Role,
} from '../../../generated/backend/graphql';

const useStyles = makeStyles<Theme>(theme => ({
  device: {
    alignItems: 'center',
    display: 'flex',
    margin: '8px 0',
  },
  deviceStatus: {
    backgroundColor: '#fff',
    borderRadius: 5,
    height: 10,
    marginRight: 8,
    width: 10,
  },
  deviceStatusNotSet: {
    backgroundColor: '#999',
  },
  deviceStatusNotDetermined: {
    backgroundColor: '#d90',
  },
  deviceStatusAuthorized: {
    backgroundColor: '#090',
  },
  deviceStatusDenied: {
    backgroundColor: '#b00',
  },
  avatarDisplay: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  sessionGrid: {
    height: '400px',
  },
}));

type KeyedTuple<T> = { [K in keyof T]: { key: K; value: T[K] } }[keyof T];
export type Action = KeyedTuple<
  BofUserUserFragment & {
    userUnsubscribedChannels: BofUserUnsubscribedChannelsFragment;
  }
>;

type UnsubscribeCheckboxProps = {
  dispatch: React.Dispatch<Action>;
  label: string;
  name: string;
  userProfile:
    | (BofUserUserFragment & {
        userUnsubscribedChannels: BofUserUnsubscribedChannelsFragment;
      })
    | undefined;
};

const UnsubscribeCheckbox = ({
  dispatch,
  label,
  name,
  userProfile,
}: UnsubscribeCheckboxProps) => {
  const userUnsubscribedChannels = userProfile?.userUnsubscribedChannels;

  const channel = userUnsubscribedChannels?.channels.find(c => c.name === name);

  return (
    <Box>
      <FormControlLabel
        control={
          <Checkbox
            checked={!channel}
            name={name}
            onChange={async () => {
              if (!userUnsubscribedChannels) {
                return;
              }

              const updatedUnsubscribedChannels =
                userUnsubscribedChannels.channels.filter(c => c.name !== name);

              if (!channel) {
                updatedUnsubscribedChannels.push({
                  name,
                  createdAt: new Date().toISOString(),
                });
              }

              dispatch({
                key: 'userUnsubscribedChannels',
                value: {
                  id: userUnsubscribedChannels.id,
                  channels: updatedUnsubscribedChannels,
                },
              });
            }}
          />
        }
        label={`${label}${
          channel
            ? ` (avstängd ${new Intl.DateTimeFormat('sv-SE', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              }).format(new Date(channel.createdAt))})`
            : ''
        }`}
      />
    </Box>
  );
};

const UserProfileCard = ({
  userProfile,
  devices,
  sessions,
  dispatch,
}: {
  userProfile:
    | (BofUserUserFragment & {
        userUnsubscribedChannels: BofUserUnsubscribedChannelsFragment;
      })
    | undefined;
  devices: BofUserDeviceFragment[] | undefined;
  sessions: BofUserSessionInfoFragment[] | undefined;
  dispatch: React.Dispatch<Action>;
}) => {
  const classes = useStyles();
  const L = locale.backoffice;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <Stack spacing={3}>
          <Card>
            <CardHeader title={L.userProfile.contactDetails} />
            <CardContent>
              <TextField
                label={L.userProfile.name}
                dispatch={dispatch}
                value={userProfile?.name}
                name="name"
              />
              <TextField
                label={L.userProfile.email}
                dispatch={dispatch}
                value={userProfile?.email}
                name="email"
                type="email"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {userProfile?.emailVerified ? (
                        <Tooltip title="E-postadressen är verifierad">
                          <VerifiedUserOutlined />
                        </Tooltip>
                      ) : (
                        <Tooltip title="E-postadressen är inte verifierad">
                          <NoAccountsOutlined />
                        </Tooltip>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                label={L.userProfile.phone}
                dispatch={dispatch}
                value={userProfile?.phone}
                name="phone"
                type="tel"
              />
              <TextField
                label={L.userProfile.pin}
                dispatch={dispatch}
                value={userProfile?.pin}
                name="pin"
                type="text"
              />
            </CardContent>
          </Card>
          <Card>
            <CardHeader title={L.userProfile.administration} />
            <CardContent>
              <Box mt={2}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>{L.userProfile.role}</InputLabel>
                  <Select
                    label={L.userProfile.role}
                    value={userProfile?.role ?? ''}
                    onChange={evt =>
                      dispatch({ key: 'role', value: evt.target.value as Role })
                    }
                  >
                    <MenuItem value="" disabled>
                      Välj
                    </MenuItem>
                    {Object.entries(Role).map(([role, value]) => (
                      <MenuItem key={role} value={value}>
                        {role}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <TextField
                label={L.userProfile.displayName}
                dispatch={dispatch}
                value={userProfile?.displayName}
                name="displayName"
              />
              <TextField
                label={L.userProfile.photoURL}
                dispatch={dispatch}
                value={userProfile?.photoURL}
                name="photoURL"
                type="url"
              />
              <Box mb={2} mt={2}>
                <Typography mb={2} variant="subtitle2">
                  {L.userProfile.photoPreview}
                </Typography>
                <div className={classes.avatarDisplay}>
                  <Avatar
                    src={userProfile?.photoURL ?? undefined}
                    alt={userProfile?.name ?? undefined}
                    className={classes.large}
                  />
                  <Avatar
                    src={userProfile?.photoURL ?? undefined}
                    alt={userProfile?.name ?? undefined}
                  />
                  <Avatar
                    src={userProfile?.photoURL ?? undefined}
                    alt={userProfile?.name ?? undefined}
                    className={classes.small}
                  />
                </div>
              </Box>
              <TextField
                label={L.userProfile.teamsHook}
                dispatch={dispatch}
                value={userProfile?.teamsHook ?? ''}
                name="teamsHook"
              />
            </CardContent>
          </Card>
        </Stack>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Stack spacing={3}>
          <Card>
            <CardHeader title={L.userProfile.emailUpdates} />
            <CardContent>
              <UnsubscribeCheckbox
                dispatch={dispatch}
                label={L.userProfile.receiveRecommendationDigest}
                name="recommendations_emails"
                userProfile={userProfile}
              />
              <UnsubscribeCheckbox
                dispatch={dispatch}
                label={L.userProfile.receiveMarketDigest}
                name="market_emails"
                userProfile={userProfile}
              />
            </CardContent>
          </Card>
          <Card>
            <CardHeader title={L.userProfile.sessions} />
            <CardContent className={classes.sessionGrid}>
              <DataGrid
                rows={sessions ?? []}
                columns={[
                  { field: 'realm', headerName: 'Typ', width: 150 },
                  {
                    field: 'updatedAt',
                    headerName: 'Senast uppdaterad',
                    width: 150,
                    valueFormatter: ({ value }) =>
                      new Date(value).toISOString().slice(0, 10),
                  },
                  {
                    field: 'createdAt',
                    headerName: 'Skapad',
                    width: 150,
                    valueFormatter: ({ value }) =>
                      new Date(value).toISOString().slice(0, 10),
                  },
                ]}
                disableRowSelectionOnClick
              />
            </CardContent>
          </Card>
          <Card>
            <CardHeader title={L.userProfile.devices} />
            <CardContent>
              {devices?.map((device, i) => (
                <div key={i} className={classes.device}>
                  <span
                    className={cx(classes.deviceStatus, {
                      [classes.deviceStatusNotSet ?? '']:
                        device.notificationsPermission === 'notSet',
                      [classes.deviceStatusNotDetermined ?? '']:
                        device.notificationsPermission === 'notDetermined',
                      [classes.deviceStatusAuthorized ?? '']:
                        device.notificationsPermission === 'authorized',
                      [classes.deviceStatusDenied ?? '']:
                        device.notificationsPermission === 'denied',
                    })}
                  />{' '}
                  {}
                  {typeof device.updatedAt === 'string'
                    ? moment(device.updatedAt).toString()
                    : 'fail'}
                </div>
              ))}
            </CardContent>
          </Card>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default UserProfileCard;
