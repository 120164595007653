import { useQuery } from '@apollo/client';
import {
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Stack } from '@mui/system';
import { sv as locale } from '@norban/locale';
import React, { useCallback, useMemo, useState } from 'react';

import {
  BofHomeMessageDialogMessageFragment,
  BofHomeMessagesDocument,
  HomeMessageState,
} from '../../../generated/backend/graphql';

const L = locale.backoffice.homeMessages;

const colorMap: {
  [key in HomeMessageState]: 'warning' | 'success' | 'error';
} = {
  [HomeMessageState.Pending]: 'warning',
  [HomeMessageState.Approved]: 'success',
  [HomeMessageState.Rejected]: 'error',
  [HomeMessageState.Delivered]: 'success',
};

const useStyles = makeStyles(() => ({
  // A box for the message
  messageBody: {
    whiteSpace: 'pre-wrap',
    backgroundColor: '#f0f0f0',
    padding: '10px',
    borderLeft: '3px solid #0078D7',
    borderRadius: '5px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    fontSize: '1rem',
    color: '#333',
    marginBottom: '1em',
  },
  earlierMessageCard: {
    maxHeight: '200px',
    overflowY: 'auto',
  },
  // A slightly more subtle box for earlier messages
  earlierMessageBody: {
    whiteSpace: 'pre-wrap',
    backgroundColor: '#f8f8f8',
    padding: '8px',
    borderLeft: '2px solid #0056b3',
    borderRadius: '4px',
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.08)',
    fontSize: '0.95rem',
    color: '#4d4d4d',
    marginBottom: '0.8em',
  },
}));

type ContentProps = {
  message?: BofHomeMessageDialogMessageFragment;
  onSave: (updates: Partial<BofHomeMessageDialogMessageFragment>) => void;
  onDelete: () => void;
  onClose: () => void;
};

const HomeMessageDialogContent = ({
  message: originalMessage = undefined,
  onDelete,
  onSave,
  onClose,
}: ContentProps) => {
  const [modifiedMessage, setModifiedMessage] = useState<
    Partial<BofHomeMessageDialogMessageFragment>
  >({});
  const { data: earlierMessagesData } = useQuery(BofHomeMessagesDocument, {
    variables: {
      filter: {
        fromUserId: originalMessage?.fromUser?.user.id,
        states: [HomeMessageState.Delivered],
      },
    },
  });

  const [deleteMessage, setDeleteMessage] = useState(false);
  const [advancedMode, setAdvancedMode] = useState(false);

  const message = useMemo(
    () => ({ ...originalMessage, ...modifiedMessage }),
    [originalMessage, modifiedMessage],
  );

  const alreadyProcessed = originalMessage?.state !== HomeMessageState.Pending;

  const handleConfirm = useCallback(() => {
    if (deleteMessage) {
      onDelete();
    } else {
      onSave(modifiedMessage);
    }
  }, [deleteMessage, modifiedMessage, onDelete, onSave]);

  const styles = useStyles();

  const earlierMessagesGroupedByHomeId = useMemo<
    Map<string, BofHomeMessageDialogMessageFragment[]>
  >(
    () =>
      earlierMessagesData?.homeMessages.reduce<
        Map<string, BofHomeMessageDialogMessageFragment[]>
      >((acc, message) => {
        // Skip message if it's the same as the one we're editing
        if (message.id === originalMessage?.id) {
          return acc;
        }

        if (!acc.has(message.toHomeId)) {
          acc.set(message.toHomeId, []);
        }

        acc.get(message.toHomeId)?.push(message);
        return acc;
      }, new Map()) ?? new Map(),
    [earlierMessagesData?.homeMessages, originalMessage?.id],
  );

  return (
    <>
      <DialogTitle>{L.title}</DialogTitle>
      <DialogContent>
        <FormControlLabel
          control={
            <Checkbox
              checked={advancedMode}
              onChange={() => setAdvancedMode(!advancedMode)}
              name="advancedMode"
              inputProps={{ 'aria-label': L.edit }}
            />
          }
          label={L.edit}
        />
        <Stack spacing={2} mt={1}>
          {!advancedMode ? (
            <>
              <Typography variant="body1">
                Från: {message.fromUser?.user.name}
              </Typography>
              <Typography variant="body1" className={styles.messageBody}>
                {message.message}
              </Typography>
              <Stack direction="row" spacing={1} justifyContent="space-between">
                <RadioGroup
                  aria-label="Välj besult om hälsning"
                  name="Välj besult om hälsning"
                  value={
                    deleteMessage ? 'delete' : message?.state ?? 'unchanged'
                  }
                  onChange={(_event, value) => {
                    if (value === 'delete') {
                      setDeleteMessage(true);
                      const { state, ...rest } = modifiedMessage;
                      setModifiedMessage(rest);
                    } else {
                      setDeleteMessage(false);
                      setModifiedMessage({
                        ...modifiedMessage,
                        state: value as HomeMessageState,
                      });
                    }
                  }}
                  row
                >
                  <FormControlLabel
                    value={HomeMessageState.Rejected}
                    control={<Radio color="primary" />}
                    label={L.reject}
                    labelPlacement="end"
                    disabled={alreadyProcessed}
                  />
                  <FormControlLabel
                    value={HomeMessageState.Approved}
                    control={<Radio color="primary" />}
                    label={L.approve}
                    labelPlacement="end"
                    disabled={alreadyProcessed}
                  />
                  <FormControlLabel
                    value="delete"
                    control={<Radio color="primary" />}
                    label={L.delete}
                    labelPlacement="end"
                    disabled={alreadyProcessed}
                  />
                </RadioGroup>
                <Chip
                  label={message?.state ? L[message.state] : 'Error'}
                  color={message?.state ? colorMap[message.state] : 'error'}
                />
              </Stack>
              {earlierMessagesGroupedByHomeId.size > 0 && (
                <>
                  <Typography variant="body1" mb={2}>
                    {L.earlierMessagesTitle}
                  </Typography>
                  {[...earlierMessagesGroupedByHomeId.entries()]
                    .sort(([a], [b]) => {
                      if (a === originalMessage?.toHomeId) {
                        return -1;
                      }

                      if (b === originalMessage?.toHomeId) {
                        return 1;
                      }

                      return 0;
                    })
                    .map(([homeId, messages]) => (
                      <>
                        <Typography variant="body1" mb={2}>
                          {`${
                            homeId === originalMessage?.toHomeId
                              ? L.sameHome
                              : L.otherHome
                          } (${homeId})`}
                        </Typography>
                        {messages.map(({ message }, idx) => (
                          <Typography
                            key={idx}
                            variant="body1"
                            className={styles.earlierMessageBody}
                          >
                            {message}
                          </Typography>
                        ))}
                      </>
                    ))}
                </>
              )}
            </>
          ) : (
            <>
              <TextField
                label={L.message}
                fullWidth
                value={message.message}
                onChange={({ target }) =>
                  setModifiedMessage({
                    ...modifiedMessage,
                    message: target.value,
                  })
                }
                rows={5}
                multiline
              />
              <FormControl>
                <InputLabel shrink htmlFor="select-message-state">
                  {L.state}
                </InputLabel>
                <Select
                  label={L.state}
                  inputProps={{
                    id: 'select-message-state',
                  }}
                  value={message.state}
                  onChange={({ target }) =>
                    setModifiedMessage({
                      ...modifiedMessage,
                      state: target.value as HomeMessageState,
                    })
                  }
                >
                  <MenuItem value={HomeMessageState.Pending}>
                    {L.PENDING}
                  </MenuItem>
                  <MenuItem value={HomeMessageState.Approved}>
                    {L.APPROVED}
                  </MenuItem>
                  <MenuItem value={HomeMessageState.Rejected}>
                    {L.REJECTED}
                  </MenuItem>
                  <MenuItem value={HomeMessageState.Delivered}>
                    {L.DELIVERED}
                  </MenuItem>
                </Select>
              </FormControl>
            </>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {L.cancel}
        </Button>
        <Button
          onClick={handleConfirm}
          color="primary"
          disabled={Object.keys(modifiedMessage).length === 0 && !deleteMessage}
        >
          {deleteMessage ? L.delete : L.confirm}
        </Button>
      </DialogActions>
    </>
  );
};

type Props = ContentProps & {
  open: boolean;
};

const HomeMessageDialog = ({
  message = undefined,
  open,
  onDelete,
  onSave,
  onClose,
}: Props) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <HomeMessageDialogContent
        key={message?.id}
        message={message}
        onDelete={onDelete}
        onSave={onSave}
        onClose={onClose}
      />
    </Dialog>
  );
};

export default HomeMessageDialog;
