import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { ClearOutlined } from '@mui/icons-material';
import { Box, IconButton, Stack, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { sv as locale } from '@norban/locale';
import React, { useCallback, useMemo, useState } from 'react';

import { CONTENT_URI } from '../config';
import {
  BofEmailDialogLookupAdminDocument,
  BofUserEmailDialogSendUserEmailDocument,
} from '../generated/backend/graphql';
import usePopupAlert from '../hooks/usePopupAlert';
import useSession from '../hooks/useSession';

import WizardDialog from './WizardDialog';

type Props = {
  open: boolean;
  userId: string;
  onClose: () => void;
  onSent: () => void;
};

const useStyles = makeStyles(() => ({
  iframe: {
    height: 240,
    width: '100%',
  },
}));

const UserEmailDialog = ({ open, userId, onClose, onSent }: Props) => {
  const L = locale.backoffice;
  const classes = useStyles();
  const { PopupAlert, showPopupAlert } = usePopupAlert();

  const [body, setBody] = useState('');
  const [subject, setSubject] = useState<string>('');
  const { userId: adminId } = useSession();

  const { data: staticLookupData } = useQuery(
    BofEmailDialogLookupAdminDocument,
    {
      variables: {
        id: adminId ? `${adminId}` : '',
      },
      skip: !adminId,
    },
  );

  const EmailPreview = useCallback(() => {
    const uri = new URL(`${CONTENT_URI}/email-template/sv/user-email.html`);

    uri.searchParams.set(
      'variables',
      JSON.stringify({
        body,
        signatureAvatar: staticLookupData?.user.photoURL,
        signatureName: staticLookupData?.user.name,
        signaturePhone: staticLookupData?.user.phone,
      }),
    );

    return (
      <iframe
        className={classes.iframe}
        title="email-preview"
        src={uri ? uri.toString() : undefined}
      />
    );
  }, [
    body,
    classes.iframe,
    staticLookupData?.user.name,
    staticLookupData?.user.phone,
    staticLookupData?.user.photoURL,
  ]);

  const reset = useCallback(() => {
    setBody('');
    setSubject('');
  }, []);

  const [sendUserEmail] = useMutation(BofUserEmailDialogSendUserEmailDocument, {
    update: cache => {
      cache.evict({
        id: 'ROOT_QUERY',
        fieldName: 'logEntries',
      });
      cache.gc();
    },
    onCompleted: () => {
      reset();
      onSent();
      onClose();
    },
  });

  const handleSendMail = async () => {
    try {
      await sendUserEmail({
        variables: {
          input: {
            body,
            subject,
            userId,
          },
        },
      });
      showPopupAlert(L.sent, 'success');
    } catch (err) {
      const ae = err as ApolloError;
      showPopupAlert(ae.message, 'error');
    }
  };

  const handleClose = () => {
    reset();
    onClose();
  };

  const isValidEmail = useMemo(() => {
    return subject.length > 0 && body.length > 0;
  }, [subject, body]);

  const renderEditStep = () => {
    return (
      <Stack mb={2} mt={4} spacing={2}>
        <TextField
          label={L.emailDialog.subject}
          fullWidth
          value={subject}
          onChange={ev => setSubject(ev.target.value)}
          InputProps={{
            endAdornment: (
              <IconButton
                onClick={() => {
                  setSubject('');
                }}
              >
                <ClearOutlined />
              </IconButton>
            ),
          }}
        />
        <TextField
          label={L.emailDialog.body}
          multiline
          maxRows={10}
          minRows={5}
          value={body}
          fullWidth
          onChange={ev => setBody(ev.target.value)}
        />
      </Stack>
    );
  };

  return (
    <>
      <WizardDialog
        open={open}
        title={L.emailDialog.title}
        steps={[
          {
            label: L.emailDialog.step3,
            content: renderEditStep(),
            disableNext: !isValidEmail,
          },
          {
            label: L.emailDialog.step4,
            content: (
              <Box mt={4}>
                <EmailPreview />
              </Box>
            ),
            disableNext: false,
          },
        ]}
        onCompleted={handleSendMail}
        onClose={handleClose}
      />
      <PopupAlert />
    </>
  );
};

export default UserEmailDialog;
