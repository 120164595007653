import { Checkbox } from '@mui/material';
import React from 'react';

const DispatchCheckbox = ({ label, checked, dispatch, name }) => (
  <Checkbox
    label={label}
    checked={!!checked}
    onChange={evt => dispatch({ key: name, value: evt.target.checked })}
  />
);

export default DispatchCheckbox;
