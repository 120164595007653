import { useQuery } from '@apollo/client';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import {
  CardContent,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridRowParams,
  GridSortModel,
  gridDateComparator,
  gridNumberComparator,
} from '@mui/x-data-grid';
import { sv as locale } from '@norban/locale';
import React, { useState } from 'react';

import PageHeader from '../../components/PageHeader';
import RootCard from '../../components/RootCard';
import {
  AgreementContract,
  AgreementContractCrmState,
  AgreementContractSource,
  BofAllAgreementContractsDocument,
} from '../../generated/backend/graphql';

const AgreementContracts = () => {
  const L = locale.backoffice;
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: 'signeeCount', sort: 'desc' },
  ]);

  const [displayMode, setDisplayMode] = useState<'archived' | 'ongoing'>(
    'ongoing',
  );

  const { loading, data, error } = useQuery(BofAllAgreementContractsDocument, {
    variables: {
      filter: {
        crmStates:
          displayMode === 'ongoing'
            ? [AgreementContractCrmState.Default]
            : [AgreementContractCrmState.Archived],
      },
    },
  });

  const rows = (data?.agreementContracts ?? []).map(agreementContract => ({
    ...agreementContract,
    agent: agreementContract.home.assignedAgent?.name ?? '-',
    homeAddress: agreementContract.home.address.streetAddress ?? '-',
    signeeCount: `${
      agreementContract.signees.filter(({ signedAt }) => !!signedAt).length
    }/${agreementContract.signees.length}`,
  }));

  const handleRowClick = ({ row }: GridRowParams<AgreementContract>) => {
    const { homeId, id: contractId } = row;
    window.open(
      `/homes/${homeId}/sales-process/agreement-contracts/${contractId}`,
      '_blank',
      'noreferrer',
    );
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Id',
      sortable: true,
      width: 80,
      valueGetter: ({ value }) => value,
      sortComparator: gridNumberComparator,
    },
    {
      field: 'state',
      headerName: L.state,
      sortable: true,
      width: 180,
      valueGetter: ({ row, value }) => {
        let prefix = '';
        if (row.crmState === 'ARCHIVED') {
          prefix = `${L.agreementContract.crmStates.ARCHIVED} - `;
        }

        if (row.signState === 'SIGNED') {
          return `${prefix}${L.agreementContract.SIGNED}`;
        }

        return `${prefix}${
          L.agreementContract[
            value as 'DRAFT' | 'ACTIVATED' | 'FINALIZED' | 'CANCELLED'
          ]
        }`;
      },
    },
    {
      field: 'userId',
      sortable: true,
      width: 80,
      renderHeader: () => <PersonIcon />,
      renderCell: ({ value }) => (
        <a
          onClick={e => e.stopPropagation()}
          href={`/users/${value}`}
          target="_blank"
          rel="noreferrer"
        >
          {value}
        </a>
      ),
      sortComparator: gridNumberComparator,
    },
    {
      field: 'homeId',
      sortable: true,
      width: 80,
      renderHeader: () => <HomeIcon />,
      renderCell: ({ value }) => (
        <a
          onClick={e => e.stopPropagation()}
          href={`/homes/${value}`}
          target="_blank"
          rel="noreferrer"
        >
          {value}
        </a>
      ),
      sortComparator: gridNumberComparator,
    },
    {
      field: 'homeAddress',
      headerName: L.agreementContract.address,
      sortable: true,
      width: 150,
      valueGetter: ({ value }) => value,
    },
    {
      field: 'agent',
      headerName: L.agreementContract.agent,
      sortable: true,
      width: 150,
      valueGetter: ({ value }) => value,
    },
    {
      field: 'createdAt',
      headerName: L.created,
      sortable: true,
      width: 100,
      valueGetter: ({ value }) => value,
      renderCell: ({ value }) =>
        value
          ? new Intl.DateTimeFormat('sv-SE', {
              day: 'numeric',
              month: 'short',
              hour: 'numeric',
              minute: 'numeric',
            }).format(new Date(value))
          : '',
      sortComparator: gridDateComparator,
    },
    {
      field: 'updatedAt',
      headerName: L.updated,
      sortable: true,
      width: 100,
      valueGetter: ({ value }) => value,
      renderCell: ({ value }) =>
        value
          ? new Intl.DateTimeFormat('sv-SE', {
              day: 'numeric',
              month: 'short',
              hour: 'numeric',
              minute: 'numeric',
            }).format(new Date(value))
          : '',
      sortComparator: gridDateComparator,
    },
    {
      field: 'source',
      headerName: L.agreementContract.source,
      sortable: true,
      width: 80,
      valueGetter: ({ value }) =>
        value ? L.agreementContract[value as AgreementContractSource] : '',
    },
    {
      field: 'signeeCount',
      headerName: L.agreementContract.signCount,
      sortable: true,
      width: 120,
      valueGetter: ({ value }) => value,
    },
  ];

  return (
    <>
      <PageHeader title={L.allAgreementContracts.title} />
      <RootCard>
        <CardContent>
          {loading && <div>{L.loading}</div>}
          {error && <div>{error.message}</div>}
          <RadioGroup
            aria-label="Filterinställning"
            value={displayMode}
            onChange={evt =>
              setDisplayMode(
                evt.target.value === 'ongoing' ? 'ongoing' : 'archived',
              )
            }
            row
          >
            <FormControlLabel
              value="ongoing"
              control={<Radio />}
              label={L.agreementContract.ongoing}
            />
            <FormControlLabel
              value="archived"
              control={<Radio />}
              label={L.agreementContract.archived}
            />
          </RadioGroup>
        </CardContent>
        <DataGrid
          autoHeight
          columns={columns}
          rows={rows}
          onRowClick={handleRowClick}
          disableRowSelectionOnClick
          sortModel={sortModel}
          onSortModelChange={setSortModel}
        />
      </RootCard>
    </>
  );
};

export default AgreementContracts;
