import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { ClearOutlined } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import { Stack } from '@mui/system';
import { sv as locale } from '@norban/locale';
import {
  formatNumber,
  formatViewingDate,
  formatViewingTime,
  generateDefaultImage,
  labelColor,
  upcomingViewingDates,
  viewingIsFull,
} from '@norban/utils';
import { formatViewingDay } from '@norban/utils/dist/home/viewing';
import React, { useCallback, useMemo, useState } from 'react';

import { CONTENT_URI, WEB_URI } from '../config';
import {
  BofEmailDialogLookupAdminDocument,
  BofEmailDialogLookupHomeDocument,
  BofEmailDialogViewingFragment,
  BofSendBulkEmailDocument,
  BulkEmailType,
  BulkEmailUnsubscribeType,
  UserFilter,
} from '../generated/backend/graphql';
import usePopupAlert from '../hooks/usePopupAlert';
import useSession from '../hooks/useSession';

import HomeLookup from './HomeLookup';
import WizardDialog from './WizardDialog';

interface Recipient {
  id: string;
  email: string;
}

type Props<T extends Recipient> = {
  defaultType?: BulkEmailType;
  recipients: T[] | undefined;
  homeId?: number;
  open: boolean;
  typeDatas: {
    type: BulkEmailType;
    unsubscribeType: BulkEmailUnsubscribeType;
    description: string;
    message?: string;
  }[];
  userFilter: UserFilter | undefined;
  userFilterCount: number;
  onClose: () => void;
  onSent: () => void;
};

const WARNING_LIMIT = 100;

const useStyles = makeStyles(() => ({
  iframe: {
    height: 320,
    width: '100%',
  },
}));

const typeToTemplateMap = {
  [BulkEmailType.Custom]: 'bulk-email',
  [BulkEmailType.FollowerCustomWithHome]: 'custom-with-home',
  [BulkEmailType.FollowerGenericUpdate]: 'generic-update',
  [BulkEmailType.FollowerListingUpdated]: 'listing-updated',
  [BulkEmailType.FollowerNowOnPremarket]: 'now-on-premarket',
  [BulkEmailType.FollowerPhotosPublished]: 'photos-published',
  [BulkEmailType.FollowerUpdate]: 'follower-update',
  [BulkEmailType.FollowerViewingScheduled]: 'viewing-scheduled',
  [BulkEmailType.MarketingSoldWithoutPrice]: 'marketing-sold-home',
  [BulkEmailType.MarketingSoldWithPrice]: 'marketing-sold-home',
  [BulkEmailType.MatchingHomes]: 'matching-homes',
  [BulkEmailType.FollowerSoldUpdate]: 'follower-sold-update',
  [BulkEmailType.MatchProperty]: 'match-property',
  [BulkEmailType.CampaignFollowers_2024Mar]: 'campaign-followers-2024-mar',
};

const EmailDialog = <T extends Recipient>({
  defaultType = BulkEmailType.Custom,
  recipients,
  homeId = undefined,
  open,
  typeDatas,
  userFilter,
  userFilterCount,
  onClose,
  onSent,
}: Props<T>) => {
  const L = locale.backoffice;
  const LSubjects = locale.mail.subjects;
  const classes = useStyles();
  const { response } = L.emailDialog;
  const { userId: adminId } = useSession();

  const [selectedType, setSelectedType] = useState<BulkEmailType>(defaultType);
  const [recipientSubset, setRecipientSubset] = useState<'list' | 'filtered'>(
    'list',
  );
  const [lookupWarning, setLookupWarning] = useState('');
  const { PopupAlert, showPopupAlert } = usePopupAlert();

  const selectedUnsubscribeType = useMemo(() => {
    const typeData = typeDatas.find(template => template.type === selectedType);
    return typeData?.unsubscribeType ?? BulkEmailUnsubscribeType.Marketing;
  }, [selectedType, typeDatas]);
  const getTypeData = useCallback(
    (aCategory: BulkEmailType) =>
      typeDatas.find(template => template.type === aCategory),
    [typeDatas],
  );
  const selectedTypeData = useMemo(
    () => getTypeData(selectedType),
    [selectedType, getTypeData],
  );
  const defaultMessage = useMemo(
    () => selectedTypeData?.message ?? '',
    [selectedTypeData?.message],
  );

  const [body, setBody] = useState(defaultMessage);
  const [modifiedHomeId, setModifiedHomeId] = useState<number | undefined>(
    homeId,
  );
  const currentHomeId = modifiedHomeId ?? homeId;

  const selectedUserFilter = useMemo(() => {
    if (recipientSubset === 'list') {
      return { ids: recipients?.map(recipient => recipient.id) ?? [] };
    }

    return userFilter ?? { ids: [] };
  }, [recipients, recipientSubset, userFilter]);
  const recipientCount = useMemo(() => {
    if (recipientSubset === 'list') {
      return recipients?.length ?? 0;
    }
    return userFilterCount;
  }, [recipients?.length, recipientSubset, userFilterCount]);

  const [modifiedSubject, setModifiedSubject] = useState<string>();

  const templateName = selectedTypeData?.type
    ? typeToTemplateMap[selectedTypeData.type]
    : undefined;

  const defaultSubject =
    templateName !== undefined && templateName in LSubjects
      ? LSubjects[templateName as keyof typeof LSubjects].subject
      : '';
  const subject = modifiedSubject ?? defaultSubject;

  const clearText = useCallback(() => setBody(''), []);

  // FIXME - remove and reactively render the lookup data
  const processTemplate = useCallback(
    (
      type: BulkEmailType,
      address: string | undefined,
      viewingStartsAt: string | undefined,
    ) => {
      if (type === BulkEmailType.Custom) {
        clearText();
        setModifiedSubject(undefined);
      } else {
        const template = getTypeData(type);

        let templateMessage = template?.message ?? '';
        const templateName = template?.type
          ? typeToTemplateMap[template.type]
          : undefined;

        let templateTitle =
          templateName !== undefined && templateName in LSubjects
            ? LSubjects[templateName as keyof typeof LSubjects].subject
            : '';
        if (
          (templateMessage.includes('{address}') && !address) ||
          (templateMessage.includes('{time}') && !viewingStartsAt)
        ) {
          // If the user has completed the lookup, set the warning to inform the user about the missing information
          if (currentHomeId) {
            setLookupWarning(
              'Information för att fylla i mallen saknas för denna bostad!',
            );
          }
          clearText();
          setModifiedSubject(undefined);
        } else {
          setLookupWarning('');
          if (address) {
            templateMessage = templateMessage.replace('{address}', address);
            templateTitle = templateTitle.replace('{address}', address);
          }
          if (viewingStartsAt) {
            templateMessage = templateMessage.replace(
              '{time}',
              viewingStartsAt,
            );
          }
          setBody(templateMessage);
          setModifiedSubject(templateTitle);
        }
      }
    },
    [clearText, getTypeData, LSubjects, currentHomeId],
  );

  const upcomingViewingTime = useCallback(
    (viewings?: BofEmailDialogViewingFragment[]) => {
      const nextViewing = upcomingViewingDates(viewings ?? []).filter(
        viewing => !viewingIsFull(viewing),
      )[0];

      if (!nextViewing) {
        return '';
      }

      const viewingDay = formatViewingDay(nextViewing);
      const viewingDate = formatViewingDate(nextViewing);
      const viewingTime = formatViewingTime(nextViewing);

      if (!viewingDay || !viewingDate || !viewingTime) {
        return '';
      }

      return `${viewingDay} den ${viewingDate} kl ${viewingTime}`;
    },
    [],
  );

  // Lookup query to get information to fill out the template
  const { data: staticLookupData } = useQuery(
    BofEmailDialogLookupAdminDocument,
    {
      variables: {
        id: adminId ? `${adminId}` : '',
      },
      skip: !adminId,
    },
  );
  const { data: lookupData, loading: lookupLoading } = useQuery(
    BofEmailDialogLookupHomeDocument,
    {
      variables: {
        homeId: currentHomeId ? `${currentHomeId}` : '',
      },
      skip: !currentHomeId,
      onError: () => {
        setLookupWarning('Misslyckades slå upp bostaden!');
      },
      onCompleted: data => {
        if (!selectedTypeData?.type) {
          clearText();
          return;
        }

        const streetAddress = data.home?.address.publicStreetAddress;
        const time = upcomingViewingTime(data.home?.viewings);

        processTemplate(
          selectedTypeData.type,
          streetAddress ?? undefined,
          time,
        );
      },
    },
  );

  const EmailPreview = useCallback(
    (props: { unsubscribeType: BulkEmailUnsubscribeType }) => {
      const { unsubscribeType } = props;

      const includePrice =
        selectedType === BulkEmailType.MarketingSoldWithPrice &&
        lookupData?.home?.closingPrice;

      const uri = templateName
        ? new URL(`${CONTENT_URI}/email-template/sv/${templateName}.html`)
        : undefined;

      if (uri) {
        uri.searchParams.set(
          'variables',
          JSON.stringify({
            address: lookupData?.home.address.publicStreetAddress ?? '',
            area: lookupData?.home.area?.name ?? '',
            body,
            image: lookupData?.home.publicHeroImage?.id
              ? `${CONTENT_URI}/images/h400@2x/${lookupData.home.publicHeroImage.id}.jpg`
              : `${WEB_URI}${generateDefaultImage(lookupData?.home.id, false)}`,
            link: '{link}',
            cardLink: '{cardLink}',
            state: lookupData?.home.state
              ? L.enumerations.HomeState[lookupData.home.state]
              : '',
            stateColor: lookupData?.home.state
              ? labelColor(lookupData.home.state)
              : '#000',
            signatureAvatar: staticLookupData?.user.photoURL,
            signatureName: staticLookupData?.user.name,
            signaturePhone: staticLookupData?.user.phone,
            unsubscribeLink: '{unsubscribeLink}',
            price: includePrice
              ? formatNumber(lookupData.home.closingPrice)
              : undefined,
            unsubscribeType,
          }),
        );
      }

      return (
        <iframe
          className={classes.iframe}
          title="email-preview"
          src={uri ? uri.toString() : undefined}
        />
      );
    },
    [
      selectedType,
      lookupData?.home.closingPrice,
      lookupData?.home.address.publicStreetAddress,
      lookupData?.home.area?.name,
      lookupData?.home.publicHeroImage?.id,
      lookupData?.home.id,
      lookupData?.home.state,
      templateName,
      classes.iframe,
      body,
      L.enumerations.HomeState,
      staticLookupData?.user.photoURL,
      staticLookupData?.user.name,
      staticLookupData?.user.phone,
    ],
  );

  const resetAll = useCallback(() => {
    setBody(defaultMessage);
    setModifiedSubject(defaultSubject);

    const streetAddress = lookupData?.home?.address.publicStreetAddress;
    const time = upcomingViewingTime(lookupData?.home?.viewings);

    processTemplate(defaultType, streetAddress ?? undefined, time);

    setSelectedType(defaultType);
    setRecipientSubset('list');
    setLookupWarning('');
    setModifiedHomeId(undefined);
  }, [
    defaultMessage,
    defaultSubject,
    defaultType,
    lookupData?.home?.address.publicStreetAddress,
    lookupData?.home?.viewings,
    processTemplate,
    upcomingViewingTime,
  ]);

  const [sendBulkEmail] = useMutation(BofSendBulkEmailDocument, {
    update: cache => {
      cache.evict({
        id: 'ROOT_QUERY',
        fieldName: 'logEntries',
      });
      cache.gc();
    },
    onCompleted: () => {
      resetAll();
      onSent();
      onClose();
    },
  });

  const handleSendMail = async () => {
    try {
      await sendBulkEmail({
        variables: {
          template: selectedType,
          userFilter: selectedUserFilter,
          subject,
          body,
          unsubscribeType: selectedUnsubscribeType,
          homeId: currentHomeId ? `${currentHomeId}` : undefined,
          response,
        },
      });
      showPopupAlert(L.sent, 'success');
    } catch (err) {
      const ae = err as ApolloError;
      showPopupAlert(ae.message, 'error');
    }
  };

  const handleClose = () => {
    resetAll();
    onClose();
  };

  const isValidEmail = useMemo(() => {
    if (selectedType === BulkEmailType.Custom) {
      return subject.length > 0 && body.length > 0;
    }
    if (
      selectedType === BulkEmailType.FollowerGenericUpdate ||
      selectedType === BulkEmailType.FollowerCustomWithHome ||
      selectedType === BulkEmailType.FollowerListingUpdated ||
      selectedType === BulkEmailType.FollowerNowOnPremarket ||
      selectedType === BulkEmailType.FollowerPhotosPublished ||
      selectedType === BulkEmailType.FollowerViewingScheduled ||
      selectedType === BulkEmailType.MarketingSoldWithoutPrice ||
      selectedType === BulkEmailType.MarketingSoldWithPrice ||
      selectedType === BulkEmailType.MatchProperty ||
      selectedType === BulkEmailType.FollowerSoldUpdate
    ) {
      return body.length > 0 && !!lookupData?.home;
    }
    if (selectedType === BulkEmailType.CampaignFollowers_2024Mar) {
      return true;
    }

    return false;
  }, [selectedType, subject, body, lookupData?.home]);

  const renderEditStep = () => {
    if (selectedType === BulkEmailType.CampaignFollowers_2024Mar) {
      return <Box mt={4}>{L.emailDialog.nothingToEdit}</Box>;
    }

    return (
      <>
        <Box mt={4}>
          <TextField
            label={L.emailDialog.subject}
            fullWidth
            value={subject}
            onChange={ev => setModifiedSubject(ev.target.value)}
            InputProps={{
              endAdornment: (
                <IconButton
                  onClick={() => {
                    setModifiedSubject(undefined);
                  }}
                >
                  <ClearOutlined />
                </IconButton>
              ),
            }}
          />
        </Box>
        {selectedType !== BulkEmailType.Custom && (
          <>
            <HomeLookup
              homeId={homeId}
              loading={lookupLoading}
              streetAddress={
                lookupData?.home?.address?.publicStreetAddress ?? undefined
              }
              onChangeHomeId={updatedHomeId => setModifiedHomeId(updatedHomeId)}
            />
            {lookupWarning && <Alert severity="warning">{lookupWarning}</Alert>}
          </>
        )}
        <Box mt={2} mb={2}>
          <TextField
            label={L.emailDialog.body}
            multiline
            maxRows={10}
            minRows={5}
            value={body}
            fullWidth
            onChange={ev => setBody(ev.target.value)}
            disabled={
              selectedType !== BulkEmailType.Custom && !lookupData?.home
            }
          />
        </Box>
      </>
    );
  };

  return (
    <>
      <WizardDialog
        open={open}
        title={L.emailDialog.title}
        steps={[
          {
            label: L.emailDialog.step1,
            content: (
              <Stack spacing={2} mt={4} mb={2}>
                <RadioGroup
                  aria-label={L.emailDialog.selection}
                  name={L.emailDialog.selection}
                  value={recipientSubset}
                  onChange={evt =>
                    setRecipientSubset(evt.target.value as 'list' | 'filtered')
                  }
                >
                  <FormControlLabel
                    value="list"
                    control={<Radio color="primary" />}
                    label={L.emailDialog.selected}
                    labelPlacement="end"
                    disabled={recipients?.length === 0}
                  />
                  <FormControlLabel
                    value="filtered"
                    control={<Radio color="primary" />}
                    label={L.emailDialog.filtered}
                    labelPlacement="end"
                    disabled={userFilterCount <= 0}
                  />
                </RadioGroup>
                <Accordion
                  sx={{
                    bgcolor: grey.A200,
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>
                      {`${L.emailDialog.numberOfRecepients}: ${recipientCount}`}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      {recipientSubset === 'list'
                        ? recipients?.map(({ email }) => email).join(', ') ?? ''
                        : L.emailDialog.accordingToFilter}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                {recipientCount > WARNING_LIMIT && (
                  <Alert severity="warning">
                    {L.emailDialog.warning.replace(
                      '{warning}',
                      `${WARNING_LIMIT}`,
                    )}
                  </Alert>
                )}
              </Stack>
            ),
            disableNext: recipientCount === 0,
          },
          {
            label: L.emailDialog.step2,
            content: (
              <Box mt={4}>
                <RadioGroup
                  aria-label="Val av mall"
                  name="Val av mall"
                  value={selectedType}
                  onChange={evt => {
                    const newCategory = evt.target.value as BulkEmailType;

                    setSelectedType(newCategory);

                    const streetAddress =
                      lookupData?.home?.address.publicStreetAddress;
                    const time = upcomingViewingTime(
                      lookupData?.home?.viewings,
                    );

                    processTemplate(
                      newCategory,
                      streetAddress ?? undefined,
                      time,
                    );
                  }}
                  row
                >
                  {typeDatas.map(template => (
                    <FormControlLabel
                      key={template.type}
                      value={template.type}
                      control={<Radio color="primary" />}
                      label={template.description}
                      labelPlacement="end"
                    />
                  ))}
                </RadioGroup>
              </Box>
            ),
            disableNext: false,
          },
          {
            label: L.emailDialog.step3,
            content: renderEditStep(),
            disableNext: !isValidEmail,
            onBack: () => {
              setModifiedHomeId(undefined);
              setLookupWarning('');
            },
          },
          {
            label: L.emailDialog.step4,
            content: (
              <Box mt={4}>
                <EmailPreview unsubscribeType={selectedUnsubscribeType} />
              </Box>
            ),
            disableNext: false,
          },
        ]}
        onCompleted={handleSendMail}
        onClose={handleClose}
      />
      <PopupAlert />
    </>
  );
};

export default EmailDialog;
