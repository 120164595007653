import LinkIcon from '@mui/icons-material/Link';
import { Button } from '@mui/material';
import React from 'react';

const LinkButton = ({ to, content }) => (
  <Button
    startIcon={<LinkIcon />}
    size="small"
    href={to}
    color="primary"
    target="_blank"
  >
    {content}
  </Button>
);

export default LinkButton;
