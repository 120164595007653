import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Switch,
  Typography,
} from '@mui/material';
import { sv as locale } from '@norban/locale';
import React, { useState } from 'react';

import { WEB_URI } from '../../../../config';
import { BofHomeProfileHomeFragment } from '../../../../generated/backend/graphql';
import { DispatchType } from '../../hooks/useHomeProfileReducer';

type Props = {
  home: BofHomeProfileHomeFragment;
  dispatch: DispatchType;
};

const ViewingCard = ({ home, dispatch }: Props) => {
  const [logoColor, setLogoColor] = useState('offBlack');
  const L = locale.backoffice;
  const { showViewings } = home || {};

  return (
    <Card>
      <CardHeader title={L.homeProfile.viewingCard.title} />
      <CardContent>
        <FormControlLabel
          control={
            <Switch
              checked={!!showViewings}
              name="showViewings"
              onChange={e =>
                dispatch({
                  key: 'showViewings',
                  value: e.target.checked,
                })
              }
            />
          }
          label={L.homeProfile.viewingCard.showViewings}
        />
        <Typography variant="body2" color="textSecondary">
          {L.homeProfile.viewingCard.showViewingsDescription}
        </Typography>
        <Box my={2}>
          <Divider />
        </Box>
        <Typography variant="h6">{L.homeProfile.viewingCard.poster}</Typography>
        <Box mt={2}>
          <Typography variant="body2">
            {L.homeProfile.viewingCard.chooseColorDescription}
          </Typography>
        </Box>
        <RadioGroup
          aria-label={L.homeProfile.viewingCard.chooseColor}
          name={L.homeProfile.viewingCard.chooseColor}
          value={logoColor}
          onChange={evt => setLogoColor(evt.target.value)}
        >
          <FormControlLabel
            value="white"
            control={<Radio color="primary" />}
            label={L.homeProfile.viewingCard.white}
            labelPlacement="end"
          />
          <FormControlLabel
            value="offBlack"
            control={<Radio color="primary" />}
            label={L.homeProfile.viewingCard.offBlack}
            labelPlacement="end"
          />
        </RadioGroup>
        <Button
          onClick={() => {
            window.open(
              `${WEB_URI}/visnings-poster/${home.id}?logoColor=${logoColor}`,
              '_blank',
              'noopener, noreferrer',
            );
          }}
        >
          {L.open}
        </Button>
      </CardContent>
    </Card>
  );
};

export default ViewingCard;
