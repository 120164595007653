import {
  ApolloError,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import { Button, Fab, IconButton, Paper, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/system';
import {
  DataGrid,
  GridColDef,
  GridRowParams,
  GridSortModel,
  gridDateComparator,
  gridNumberComparator,
} from '@mui/x-data-grid';
import { sv as locale } from '@norban/locale';
import { MarkdownDocumentData } from '@norban/utils/dist/runtimeTypes/contentTypes';
import React, { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { Route, Switch } from 'react-router-dom';

import ConfirmationDialog from '../../../../components/ConfirmationDialog';
import { CONTENT_URI } from '../../../../config';
import {
  AgreementContract,
  AgreementContractSource,
  BofActivateAgreementContractDocument,
  BofAgreementContractsDocument,
  BofAgreementDownloadDocumentDataDocument,
  BofCancelAgreementContractDocument,
  BofCreateAgreementContractDocument,
  BofDeleteAgreementContractDocument,
} from '../../../../generated/backend/graphql';
import usePopupAlert from '../../../../hooks/usePopupAlert';
import { contractStateCopy } from '../../../../utils/agreementContract';

import AgreementContractView from './AgreementContract';

type Props = {
  homeId: string;
  userId?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(4),
    right: theme.spacing(4),
  },
}));

const AgreementContracts = ({ homeId, userId = undefined }: Props) => {
  const L = locale.backoffice;
  const classes = useStyles();
  const { url } = useRouteMatch();

  const history = useHistory();
  const { PopupAlert, showPopupAlert } = usePopupAlert();

  const [deleteId, setDeleteId] = useState(undefined);
  const [cancelId, setCancelId] = useState<string>();

  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: 'id', sort: 'asc' },
  ]);

  const { loading, data, error } = useQuery(BofAgreementContractsDocument, {
    variables: { homeId },
  });

  const [getAgreementDownloadDocumentData] = useLazyQuery(
    BofAgreementDownloadDocumentDataDocument,
  );

  const [createContract] = useMutation(BofCreateAgreementContractDocument, {
    refetchQueries: [BofAgreementContractsDocument],
  });

  const [deleteContract] = useMutation(BofDeleteAgreementContractDocument, {
    refetchQueries: [BofAgreementContractsDocument],
  });

  const [activateContract] = useMutation(BofActivateAgreementContractDocument);
  const [cancelContract] = useMutation(BofCancelAgreementContractDocument);

  const handleDeleteContract = async (id: string) => {
    try {
      await deleteContract({
        variables: {
          id,
        },
      });
    } catch (error) {
      const ae = error as ApolloError;
      showPopupAlert(ae.message, 'error');
    }
  };

  const handleActivateContract = async (id: string) => {
    try {
      await activateContract({
        variables: {
          id,
        },
      });

      showPopupAlert(L.agreementContract.contractSent, 'success');
    } catch (error) {
      const ae = error as ApolloError;
      showPopupAlert(ae.message, 'error');
    }
  };

  const handleDownloadContract = async (id: string) => {
    try {
      const contractAndSigneesData = await getAgreementDownloadDocumentData({
        variables: {
          id,
        },
      });

      const markdownDocumentData: MarkdownDocumentData = {
        markdown:
          contractAndSigneesData.data?.agreementContract?.document ?? '',
        id: contractAndSigneesData.data?.agreementContract.id ?? '',
        homeId: contractAndSigneesData.data?.agreementContract.homeId ?? '',
        signatures:
          contractAndSigneesData.data?.agreementContractSignees?.map(
            signee => ({
              name: signee.name ?? '',
              legalName: signee.legalName ?? '',
              personalnumber: signee.personalNumber ?? '',
              date: signee.signedAt ?? '',
              legalInfo: signee.legalInfo,
            }),
          ) ?? [],
      };

      // Create and download the document as a PDF
      const response = await fetch(`${CONTENT_URI}/markdown-document`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(markdownDocumentData),
      });

      // Make the browser download the file
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `Uppdragsavtal - ${homeId}.pdf`;
      a.click();
    } catch (error) {
      const ae = error as ApolloError;
      showPopupAlert(ae.message, 'error');
    }
  };

  const handleCancelContract = async (id: string) => {
    try {
      await cancelContract({
        variables: {
          id,
        },
      });

      showPopupAlert(L.agreementContract.contractCancelled, 'success');
    } catch (error) {
      const ae = error as ApolloError;
      showPopupAlert(ae.message, 'error');
    }
  };

  const handleOpenRow = (row: GridRowParams<AgreementContract>) => {
    history.push(`${url}/${row.id}`);
  };

  const handleCreateContract = async () => {
    try {
      if (!userId) {
        throw new Error('No userId');
      }

      await createContract({
        variables: {
          input: {
            userId,
            homeId,
            activate: false,
            notify: false,
            source: AgreementContractSource.BoAdmin,
          },
        },
      });
    } catch (error) {
      const ae = error as ApolloError;
      showPopupAlert(ae.message, 'error');
    }
  };

  if (!homeId) {
    return <div>{L.agreementContract.noHome}</div>;
  }

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Id',
      sortable: true,
      width: 80,
      valueGetter: ({ value }) => value,
      sortComparator: gridNumberComparator,
    },
    {
      field: 'state',
      headerName: L.state,
      sortable: true,
      width: 200,
      valueGetter: ({ row }) => contractStateCopy(row),
    },
    {
      field: 'userId',
      sortable: true,
      width: 80,
      renderHeader: () => <PersonIcon />,
      renderCell: ({ value }) => (
        <a onClick={e => e.stopPropagation()} href={`/users/${value}`}>
          {value}
        </a>
      ),
      sortComparator: gridNumberComparator,
    },
    {
      field: 'homeId',
      sortable: true,
      width: 80,
      renderHeader: () => <HomeIcon />,
      renderCell: ({ value }) => (
        <a onClick={e => e.stopPropagation()} href={`/homes/${value}`}>
          {value}
        </a>
      ),
      sortComparator: gridNumberComparator,
    },
    {
      field: 'createdAt',
      headerName: L.created,
      sortable: true,
      width: 140,
      valueGetter: ({ value }) => value,
      renderCell: ({ value }) =>
        value
          ? new Intl.DateTimeFormat('sv-SE', {
              day: 'numeric',
              month: 'short',
              hour: 'numeric',
              minute: 'numeric',
            }).format(new Date(value))
          : '',
      sortComparator: gridDateComparator,
    },
    {
      field: 'updatedAt',
      headerName: L.updated,
      sortable: true,
      width: 140,
      valueGetter: ({ value }) => value,
      renderCell: ({ value }) =>
        value
          ? new Intl.DateTimeFormat('sv-SE', {
              day: 'numeric',
              month: 'short',
              hour: 'numeric',
              minute: 'numeric',
            }).format(new Date(value))
          : '',
      sortComparator: gridDateComparator,
    },
    {
      field: 'crmState',
      headerName: L.agreementContract.crmState,
      sortable: true,
      width: 200,
      valueGetter: ({ value }) =>
        L.agreementContract.crmStates[value as 'DEFAULT' | 'ARCHIVED'],
    },
    {
      field: 'delete',
      headerName: L.delete,
      renderCell: ({ row }) => {
        const isDeletable =
          row.state === 'DRAFT' ||
          row.state === 'CANCELLED' ||
          row.crmState === 'ARCHIVED';

        return (
          <Tooltip title={L.agreementContract.removeContract}>
            <IconButton
              aria-label="delete"
              onClick={evt => {
                evt.stopPropagation();
                setDeleteId(row.id);
              }}
              disabled={loading || !isDeletable}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        );
      },
    },
    {
      field: 'activateContract',
      headerName: L.agreementContract.sendContract,
      width: 200,
      renderCell: ({ row }) => (
        <Button
          aria-label="delete"
          onClick={evt => {
            evt.stopPropagation();
            handleActivateContract(row.id);
          }}
          disabled={loading || row.state !== 'DRAFT'}
          variant="contained"
        >
          {L.agreementContract.send}
        </Button>
      ),
    },

    {
      field: 'downloadContract',
      headerName: L.agreementContract.downloadContract,
      width: 200,
      renderCell: ({ row }) => (
        <Button
          aria-label="download"
          onClick={evt => {
            evt.stopPropagation();
            handleDownloadContract(row.id);
          }}
          disabled={loading || row.state !== 'FINALIZED'}
          variant="contained"
        >
          {L.agreementContract.downloadContract}
        </Button>
      ),
    },
    {
      field: 'cancelContract',
      headerName: L.agreementContract.cancelContract,
      width: 200,
      renderCell: ({ row }) => (
        <Button
          aria-label="download"
          onClick={evt => {
            evt.stopPropagation();
            setCancelId(row.id);
          }}
          disabled={loading || row.state === 'CANCELLED'}
          variant="contained"
        >
          {L.agreementContract.cancelContract}
        </Button>
      ),
    },
  ];

  return (
    <>
      <ConfirmationDialog
        open={!!deleteId}
        onConfirm={() => {
          if (deleteId) {
            handleDeleteContract(deleteId);
            setDeleteId(undefined);
          }
        }}
        onCancel={() => setDeleteId(undefined)}
        title={L.agreementContract.confirmTitle}
        content={L.agreementContract.confirmContent}
      />
      <ConfirmationDialog
        open={!!cancelId}
        onConfirm={() => {
          if (cancelId) {
            handleCancelContract(cancelId);
            setCancelId(undefined);
          }
        }}
        onCancel={() => setCancelId(undefined)}
        title={L.agreementContract.cancelDialogTitle}
        content={L.agreementContract.cancelDialogContent}
      />
      {error && <div>{error.message}</div>}
      {loading && <div>{L.loading}</div>}
      {data?.agreementContracts && (
        <Paper>
          <DataGrid
            autoHeight
            columns={columns}
            rows={data.agreementContracts}
            onRowClick={handleOpenRow}
            disableRowSelectionOnClick
            sortModel={sortModel}
            onSortModelChange={setSortModel}
          />
        </Paper>
      )}

      <Fab
        disabled={!userId}
        color="primary"
        aria-label="add"
        className={classes.fab}
        onClick={e => {
          e.preventDefault();
          handleCreateContract();
        }}
      >
        <AddIcon />
      </Fab>
      <PopupAlert />
    </>
  );
};

const AgreementContractsRouter = ({
  homeId,
  userId = undefined,
}: {
  homeId: string;
  userId?: string;
}) => {
  const { url, path } = useRouteMatch();

  const history = useHistory();

  return (
    <Switch>
      <Route path={path} exact>
        <AgreementContracts homeId={homeId} userId={userId} />
      </Route>
      <Route
        path={`${path}/:agreementContractId`}
        render={({
          match: {
            params: { agreementContractId },
          },
        }) => (
          <AgreementContractView
            agreementContractId={agreementContractId}
            onClose={() => {
              history.replace(url);
            }}
          />
        )}
      />
    </Switch>
  );
};

export default AgreementContractsRouter;
