import { useQuery } from '@apollo/client';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import {
  DataGrid,
  GridColDef,
  gridDateComparator,
  gridNumberComparator,
} from '@mui/x-data-grid';
import { sv as locale } from '@norban/locale';
import React from 'react';

import PageHeader from '../../components/PageHeader';
import QueryError from '../../components/QueryError';
import QueryLoading from '../../components/QueryLoading';
import RootCard from '../../components/RootCard';
import { BofAllHomesDocument } from '../../generated/backend/graphql';
import {
  translateState,
  translateTenure,
  translateType,
} from '../../utils/translateEnum';

const Homes = () => {
  const L = locale.backoffice.allHomes;
  const { loading, data, error } = useQuery(BofAllHomesDocument);

  const rows = data?.homes ?? [];

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: L.homeId,
      sortable: true,
      renderHeader: () => <HomeIcon />,
      renderCell: ({ value }) => (
        <a href={`/homes/${value}`} target="_blank" rel="noreferrer">
          {value}
        </a>
      ),
      sortComparator: gridNumberComparator,
    },
    {
      field: 'userId',
      headerName: L.userId,
      sortable: true,
      renderHeader: () => <PersonIcon />,
      renderCell: ({ value }) => (
        <a href={`/users/${value}`} target="_blank" rel="noreferrer">
          {value}
        </a>
      ),
      sortComparator: gridNumberComparator,
    },
    {
      field: 'state',
      headerName: L.state,
      sortable: true,
      width: 200,
      valueGetter: ({ value }) => translateState(value),
    },
    {
      field: 'address',
      headerName: L.address,
      sortable: true,
      width: 200,
      valueGetter: ({ value: { city, streetAddress, zipCode } }) =>
        `${streetAddress ?? '-'}\n${zipCode ?? '-'} ${city ?? '-'}`,
      renderCell: ({ value }) => (
        <span style={{ whiteSpace: 'pre-wrap' }}>{value}</span>
      ),
    },
    {
      field: 'area',
      headerName: L.area,
      sortable: true,
      width: 200,
      valueGetter: ({ value }) =>
        value?.name
          ? `${value.name}${value?.public ? '' : ' - stängt område'}`
          : '-',
    },
    {
      field: 'type',
      headerName: L.type,
      sortable: true,
      width: 200,
      valueGetter: ({ value }) => translateType(value),
    },
    {
      field: 'tenure',
      headerName: L.tenure,
      sortable: true,
      width: 200,
      valueGetter: ({ value }) => translateTenure(value),
    },
    {
      field: 'created',
      headerName: L.created,
      sortable: true,
      width: 200,
      valueGetter: ({ value }) => value,
      renderCell: ({ value }) =>
        value
          ? new Intl.DateTimeFormat('sv-SE', {
              year: 'numeric',
              day: 'numeric',
              month: 'short',
            }).format(new Date(value))
          : '-',
      sortComparator: gridDateComparator,
    },
    {
      field: 'agreementDate',
      headerName: L.dateOfAgreement,
      sortable: true,
      width: 200,
      valueGetter: ({ value }) => value,
      renderCell: ({ value }) =>
        value
          ? new Intl.DateTimeFormat('sv-SE', {
              year: 'numeric',
              day: 'numeric',
              month: 'short',
            }).format(new Date(value))
          : '-',
      sortComparator: gridDateComparator,
    },
  ];

  if (loading) {
    return <QueryLoading />;
  }

  if (error) {
    return <QueryError error={error} data={data} />;
  }
  return (
    <>
      <PageHeader title={L.title} />
      <RootCard>
        <DataGrid autoHeight columns={columns} rows={rows} />
      </RootCard>
    </>
  );
};

export default Homes;
