import { useQuery } from '@apollo/client';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Alert, Box, Button, Paper, Stack, Tab, Tabs } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { sv as locale } from '@norban/locale';
import { LeadTemplate } from '@norban/utils/dist';
import React, { useState } from 'react';

import { BofLeadsTemplatesDocument } from '../../../generated/backend/graphql';

type Props = {
  userId: string;
  email?: string;
};

const useStyles = makeStyles(() => ({
  message: {
    whiteSpace: 'pre-wrap',
  },
}));

const LeadTemplates = ({ userId, email = undefined }: Props) => {
  const L = locale.backoffice.leadTemplates;
  const classes = useStyles();
  const [currentTab, setCurrentTag] = useState('interest');

  const { data, loading, error } = useQuery(BofLeadsTemplatesDocument, {
    variables: { id: currentTab, userId },
    skip: !email,
  });

  return (
    <Paper>
      <Tabs
        variant="scrollable"
        value={currentTab}
        onChange={(_event, newValue) => setCurrentTag(newValue)}
      >
        <Tab key="interest" value="interest" label={L.interest} />
        <Tab key="followUp" value="followUp" label={L.followUp} />
        <Tab key="onboarding" value="onboarding" label={L.onboarding} />
        <Tab key="hidden" value="hidden" label={L.hidden} />
      </Tabs>
      <Box p={2}>
        <Stack>
          <Box mb={2}>
            <Button
              color="secondary"
              size="small"
              variant="contained"
              startIcon={<ContentCopyIcon />}
              disabled={!email}
              onClick={
                // Launch email client with template
                () => {
                  const { leadTemplate } = data || {};
                  if (leadTemplate?.message) {
                    const subject = encodeURIComponent(leadTemplate.subject);
                    const body = encodeURIComponent(leadTemplate.message);
                    window.open(
                      `mailto:${email}?subject=${subject}&body=${body}`,
                      '_blank',
                      'noopener noreferrer',
                    );
                  }
                }
              }
            >
              {L.launch}
            </Button>
          </Box>
          {(error || !email) && (
            <Alert severity="error">
              {error ? error.message : 'Användaren saknar email'}
            </Alert>
          )}
          {data?.leadTemplate.warnings.length != null &&
            data.leadTemplate.warnings.length > 0 && (
              <Alert severity="warning">
                <strong>
                  {`Viss information nedan saknas eller kan vara felaktig: 
                  ${data.leadTemplate.warnings
                    .map(warning => L[warning as keyof LeadTemplate])
                    .join(', ')}`}
                </strong>
              </Alert>
            )}
          {data?.leadTemplate.warnings.length != null &&
            data.leadTemplate.warnings.length === 0 && (
              <Alert severity="success">
                <strong>Meddelandet är fullständigt!</strong>
              </Alert>
            )}
          <pre className={classes.message}>
            {loading && 'Laddar...'}
            {!loading && !error && <span>{data?.leadTemplate.message}</span>}
          </pre>
        </Stack>
      </Box>
    </Paper>
  );
};

export default LeadTemplates;
