import {
  Box,
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
} from '@mui/material';
import { sv as locale } from '@norban/locale';
import React from 'react';

import DispatchCheckbox from '../../../../components/form/DispatchCheckbox';
import TextField from '../../../../components/form/TextField';
import { BofHomeProfileHomeFragment } from '../../../../generated/backend/graphql';
import { DispatchType } from '../../hooks/useHomeProfileReducer';

type Props = {
  home: BofHomeProfileHomeFragment;
  dispatch: DispatchType;
};

const ListingConfigCard = ({ home, dispatch }: Props) => {
  const L = locale.backoffice.homeProfileListingConfig;

  const { adHideCommission, adHideArea, urlExtension } = home;
  const checkedHC = !!adHideCommission;
  const checkedHA = !!adHideArea;

  const urlExtError = !!/[^A-Za-z0-9-]/.exec(urlExtension ?? '');

  return (
    <Card>
      <CardHeader title={L.title} />
      <CardContent>
        <FormControlLabel
          control={
            <DispatchCheckbox
              label={L.hideCommission}
              checked={checkedHC}
              name="adHideCommission"
              dispatch={dispatch}
            />
          }
          label={L.hideCommission}
        />
        <FormControlLabel
          control={
            <DispatchCheckbox
              label={L.hideArea}
              checked={checkedHA}
              name="adHideArea"
              dispatch={dispatch}
            />
          }
          label={L.hideArea}
        />
        <Box>
          <TextField
            value={urlExtension}
            dispatch={dispatch}
            name="urlExtension"
            label={L.urlExtension}
            error={urlExtError}
            helperText={urlExtError && L.urlExtensionError}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default ListingConfigCard;
