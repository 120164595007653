import { UPDATE_FILTER } from '../actions/followerActions';
import { HomeState, FollowerUniqueType } from '../generated/backend/graphql';

const minRooms = 1;
const maxRooms = 15;

const minLivingArea = 0;
const maxLivingArea = 400;

export const defaultState = {
  roomsRange: [minRooms, maxRooms],
  livingAreaRange: [minLivingArea, maxLivingArea],
  areaIds: [],
  limit: '300',
  unique: FollowerUniqueType.Latest,
  homeStates: Object.values(HomeState),
  inactive: true,
  homeId: '',
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case UPDATE_FILTER: {
      return {
        ...state,
        ...action.filter,
      };
    }

    default:
      return state;
  }
};
