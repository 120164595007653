import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stepper,
  Step,
  StepLabel,
} from '@mui/material';
import { sv as locale } from '@norban/locale';
import React, { ReactElement, useState } from 'react';

type WizardStepType = {
  label: string;
  content: ReactElement;
  disableNext: boolean;
  onBack?: () => void;
  onNext?: () => void;
};

type Props = {
  open: boolean;
  steps: WizardStepType[];
  title: string;
  onCompleted: () => void;
  onClose: () => void;
};

function WizardDialog({ open, steps, title, onCompleted, onClose }: Props) {
  const L = locale.backoffice;
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = (stepOnNext: (() => void) | undefined) => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
    stepOnNext?.();
  };

  const handleBack = (stepOnBack: (() => void) | undefined) => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
    stepOnBack?.();
  };

  const handleClose = () => {
    setActiveStep(0);
    onClose();
  };

  const handleCompleted = () => {
    setActiveStep(0);
    onCompleted();
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map(step => (
            <Step key={step.label}>
              <StepLabel>{step.label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {steps[activeStep]?.content}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{L.close}</Button>
        <Button
          disabled={activeStep === 0}
          onClick={() => handleBack(steps[activeStep]?.onBack)}
          sx={{ mr: 1 }}
        >
          {L.back}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() =>
            activeStep === steps.length - 1
              ? handleCompleted()
              : handleNext(steps[activeStep]?.onNext)
          }
          disabled={steps[activeStep]?.disableNext ?? false}
        >
          {activeStep === steps.length - 1 ? L.send : L.next}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default WizardDialog;
